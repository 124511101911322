import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RegistrationApi } from '../../services/apis';

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Basic Validation
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const payload = {
        email: formData.email,
        first_name: formData.firstName,
        last_name: formData.lastName,
        password: formData.password,
        confirm_password: formData.confirmPassword,
      };

      const response = await RegistrationApi(payload);

      if (response) {
        setSuccess('Registration successful. Redirecting to login...');
        setTimeout(() => {
          navigate('/profile');
        }, 2000);
      }
    } catch (err) {
      setError('Registration failed. Please try again.');
      console.error('Registration Error:', err);
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f4f6f8',
        padding: '20px',
      }}
    >
      <Card
        sx={{
          width: '100%',
          maxWidth: '400px',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          backgroundColor: '#fff',
        }}
      >
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography variant="h5" sx={{ marginBottom: '16px' }}>
            Create New Account
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              type="email"
              name="email"
              variant="outlined"
              fullWidth
              required
              value={formData.email}
              onChange={handleChange}
              sx={{ marginBottom: '16px' }}
            />
            <TextField
              label="First Name"
              type="text"
              name="firstName"
              variant="outlined"
              fullWidth
              required
              value={formData.firstName}
              onChange={handleChange}
              sx={{ marginBottom: '16px' }}
            />
            <TextField
              label="Last Name"
              type="text"
              name="lastName"
              variant="outlined"
              fullWidth
              required
              value={formData.lastName}
              onChange={handleChange}
              sx={{ marginBottom: '16px' }}
            />
            <TextField
              label="Password"
              type="password"
              name="password"
              variant="outlined"
              fullWidth
              required
              value={formData.password}
              onChange={handleChange}
              sx={{ marginBottom: '16px' }}
            />
            <TextField
              label="Confirm Password"
              type="password"
              name="confirmPassword"
              variant="outlined"
              fullWidth
              required
              value={formData.confirmPassword}
              onChange={handleChange}
              sx={{ marginBottom: '16px' }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginBottom: '8px' }}
            >
              Register
            </Button>
          </form>

          {success && (
            <Typography variant="body2" color="success.main" sx={{ marginTop: '8px' }}>
              {success}
            </Typography>
          )}
          {error && (
            <Typography variant="body2" color="error" sx={{ marginTop: '8px' }}>
              {error}
            </Typography>
          )}

          <Divider sx={{ margin: '16px 0' }}>OR</Divider>

          <Typography variant="body2">
            Already have an account? <a href="/profile">Login here</a>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Register;
