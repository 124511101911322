import React, { useState } from 'react'
import './Footer.scss'
import { Box, BottomNavigation, BottomNavigationAction } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import {
  Home as HomeIcon,
  Search as SearchIcon,
  Timeline as TimelineIcon,
  AccountCircle as ProfileIcon,
  Settings as SettingsIcon
} from '@mui/icons-material'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../../services/authContext'
import {
  Container,
  Grid,
  Typography,
  IconButton,
  Stack,
  Button
} from '@mui/material'
import { SubscribeApi } from '../../services/apis'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import CallIcon from '@mui/icons-material/Call'
import EmailIcon from '@mui/icons-material/Email'
import {
  Facebook,
  Twitter,
  LinkedIn,
  Instagram,
  Apple,
  Android
} from '@mui/icons-material'
import { Fab } from '@mui/material'
import { ArrowUpward } from '@mui/icons-material'
import ImageWithLoader from '../ImageWithLoader/ImageWithLoader'

const Footer = () => {
  const [value, setValue] = React.useState(0)
  const [email, setEmail] = useState('')
  const { loginStatus } = useAuth()
  const location = useLocation()
  const currentYear = new Date().getFullYear()

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: { main: '#e48c48' },
      warning: { main: '#e48c48' }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1100,
        xl: 1536
      }
    }
  })

  const handleSubscribe = async () => {
    if (!email) {
      alert('Please enter a valid email address.')
      return
    }

    try {
      await SubscribeApi(email)
      alert('Subscription successful!')
      setEmail('') // Clear the email input after successful subscription
    } catch (error) {
      alert('Subscription failed! Please try again.')
    }
  }

  React.useEffect(() => {
    switch (location.pathname) {
      case '/add_user':
        setValue(3) // Profile page
        break
      case '/settings':
        setValue(1) // Settings page
        break
      default: // Home page
        setValue(0)
        break
    }
  }, [location])

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ bgcolor: '#0A1929', color: 'white' }}>
          <Box
            sx={{
              flexGrow: 1,
              background: '#000000',
              display: { lg: 'none', md: 'flex' }
            }}
          >
            <BottomNavigation
              showLabels
              value={value}
              className='bn'
              sx={{ backgroundColor: '#000000 !important', zIndex: 1 }}
              onChange={(event, newValue) => {
                setValue(newValue)
              }}
            >
              <BottomNavigationAction
                label='Home'
                icon={<HomeIcon />}
                component={Link}
                to='/'
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                sx={{
                  color:
                    value === 0 ? '#ffffff !important' : '#ffffff !important',
                  '&.Mui-selected': {
                    color: '#5c97c3'
                  }
                }}
              />
              <BottomNavigationAction
                label='Settings'
                icon={<SettingsIcon />}
                component={Link}
                to='/settings'
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                sx={{
                  color:
                    value === 1 ? '#ffffff !important' : '#ffffff !important',
                  '&.Mui-selected': {
                    color: '#5c97c3'
                  }
                }}
              />
              <BottomNavigationAction
                label='Search'
                icon={<SearchIcon />}
                component={Link}
                to='/search'
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                sx={{
                  color:
                    value === 2 ? '#ffffff !important' : '#ffffff !important',
                  '&.Mui-selected': {
                    color: '#5c97c3'
                  }
                }}
              />
              <BottomNavigationAction
                label='Market'
                icon={<TimelineIcon />}
                component={Link}
                to='/data'
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                sx={{
                  color:
                    value === 3 ? '#ffffff !important' : '#ffffff !important',
                  '&.Mui-selected': {
                    color: '#5c97c3'
                  }
                }}
              />
              <BottomNavigationAction
                label='Profile'
                icon={<ProfileIcon />}
                component={Link}
                to='/profile'
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                sx={{
                  color:
                    value === 4 ? '#ffffff !important' : '#ffffff !important',
                  '&.Mui-selected': {
                    color: '#5c97c3'
                  }
                }}
              />
            </BottomNavigation>
          </Box>
        </Box>
      </ThemeProvider>

      {/* --------web-footter start------------- */}

      <Box
        sx={{
          bgcolor: '#212121',
          color: '#fff',
          py: 3,
          marginBottom: { xs: '50px', sm: 0 },
          marginTop: '50px',
          paddingTop: '90px'
        }}
      >
        {/* box under whole grid */}
        <Grid container spacing={3}>
          {/* get in TOUCH grid */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                marginLeft: '90px',
                color: 'white',
                fontWeight: 'bold', // Ensure it's bold
                paddingTop: '15px',
                fontSize: '1.6rem' // Increase the font size (adjust as needed)
              }}
              variant='h6'
              gutterBottom
            >
              GET IN TOUCH
            </Typography>
            <Typography sx={{ marginLeft: '90px' }}>
              <FmdGoodIcon />
              <Button href='#' color='inherit'>
                C-49, C Block, Sector 65, Noida, Uttar Pradesh 201307, India
              </Button>
            </Typography>
            <Typography sx={{ marginLeft: '90px' }}>
              <CallIcon />
              <Button href='#' color='inherit'>
                0120-4320845
              </Button>
            </Typography>
            <Typography sx={{ marginLeft: '90px' }}>
              <EmailIcon />
              <Button href='#' color='inherit'>
                dairynews7x7@gmail.com
              </Button>
            </Typography>
            <Typography
              sx={{
                marginLeft: '90px',
                color: 'white',
                fontWeight: 'bold', // Ensure it's bold
                paddingTop: '15px',
                fontSize: '1.6rem' // Increase the font size (adjust as needed)
              }}
              variant='h6'
              gutterBottom
            >
              FOLLOW US
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  marginLeft: '70px',
                  paddingLeft: '10px',
                  display: 'flex',
                  gap: '2rem',
                  flexWrap: 'wrap'
                }}
              >
                <Link
                  to='#'
                  color='inherit'
                  sx={{
                    paddingTop: '1px',
                    paddingBottom: '1px',

                    bgcolor: 'grey',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)' // Add hover effect
                    }
                  }}
                >
                  <Facebook sx={{ color: 'white' }} />
                </Link>

                <Link
                  to='#'
                  color='inherit'
                  sx={{
                    paddingTop: '1px',
                    paddingBottom: '1px',

                    bgcolor: 'grey',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)' // Add hover effect
                    }
                  }}
                >
                  <Twitter sx={{ color: 'white' }} />
                </Link>
                <Link
                  to='#'
                  color='inherit'
                  sx={{
                    paddingTop: '1px',
                    paddingBottom: '1px',

                    bgcolor: 'grey',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)' // Add hover effect
                    }
                  }}
                >
                  <LinkedIn sx={{ color: 'white' }} />
                </Link>
                <Link
                  to='#'
                  color='inherit'
                  sx={{
                    paddingTop: '1px',
                    paddingBottom: '1px',

                    bgcolor: 'grey',
                    '&:hover': {
                      // Add hover effect
                    }
                  }}
                >
                  <Instagram sx={{ color: 'white' }} />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                marginLeft: '90px',
                color: 'white',
                fontWeight: 'bold',
                paddingTop: '15px',
                fontSize: '1.6rem'
              }}
              variant='h6'
              gutterBottom
            >
              NEWSLETTER
            </Typography>
            <Box sx={{ p: 3, textAlign: 'center' }}>
              <Typography variant='body1' sx={{ mb: 2 }}>
                Subscribe to get Newsletters and Updates on new Trending news in
                Dairy
              </Typography>
              <Stack direction='row' sx={{ mb: 2 }}>
                <Box sx={{ flex: 1 }}>
                  <input
                    type='text'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    style={{
                      width: '100%',
                      padding: '10px',
                      borderRadius: '4px',
                      borderTopRightRadius: '0px',
                      borderBottomRightRadius: '0px',
                      border: '1px solid #ccc'
                    }}
                    placeholder='Your Email'
                  />
                </Box>
                <Button
                  variant='contained'
                  onClick={handleSubscribe}
                  className='btn-primary btn'
                  sx={{
                    backgroundColor: '#e48c48',
                    color: '#212529',
                    borderTopLeftRadius: '0px',
                    borderBottomLeftRadius: '0px'
                  }}
                >
                  Sign Up
                </Button>
              </Stack>
              <small variant='caption' color='text.secondary'>
                Sign up to Subscribe
              </small>
            </Box>
          </Grid>

          {/* -------------category grid-------- */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                marginLeft: '90px',
                color: 'white',
                fontWeight: 'bold', // Ensure it's bold
                paddingTop: '15px',
                fontSize: '1.6rem' // Increase the font size (adjust as needed)
              }}
              variant='h6'
              gutterBottom
            >
              CATEGORIES
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Button
                  href='/global'
                  color='inherit'
                  sx={{
                    paddingTop: '1px',
                    paddingBottom: '1px',
                    bgcolor: '#ff9900',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)' // Add hover effect
                    }
                  }}
                >
                  <span style={{ color: 'black' }}>GLOBAL</span>
                </Button>
              </Grid>

              <Grid item xs={3}>
                <Button
                  href='indian'
                  color='inherit'
                  sx={{
                    paddingTop: '1px',
                    paddingBottom: '1px',
                    bgcolor: '#ff9900',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)' // Add hover effect
                    }
                  }}
                >
                  <span style={{ color: 'black' }}>INDIAN</span>
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  href='/blogs'
                  color='inherit'
                  sx={{
                    paddingTop: '1px',
                    paddingBottom: '1px',
                    bgcolor: '#ff9900',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)' // Add hover effect
                    }
                  }}
                >
                  <span style={{ color: 'black' }}>BLOGS</span>
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  href='/blogs'
                  color='inherit'
                  sx={{
                    paddingTop: '1px',
                    paddingBottom: '1px',
                    bgcolor: '#ff9900',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)' // Add hover effect
                    }
                  }}
                >
                  <span style={{ color: 'black' }}>Vlogs</span>
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  href='/blogs'
                  color='inherit'
                  sx={{
                    paddingTop: '1px',
                    paddingBottom: '1px',
                    bgcolor: '#ff9900',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)' // Add hover effect
                    }
                  }}
                >
                  <span style={{ color: 'black' }}>DairyPolicies</span>
                </Button>
              </Grid>
              {/* Continue adding other buttons here */}
            </Grid>
          </Grid>
          {/* -----------------category grid end-------- */}

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                marginLeft: '90px',
                color: 'white',
                fontWeight: 'bold',
                padding: '15px',
                fontSize: '1.6rem'
              }}
              variant='h6'
              gutterBottom
            >
              Terms & Policies
            </Typography>
            <Box sx={{ marginLeft: '90px' }}>
              <Button
                href='/terms'
                color='inherit'
                sx={{
                  display: 'block',
                  mb: 1,
                  '&:hover': {
                    color: '#ff9900'
                  }
                }}
              >
                Terms of Service
              </Button>
              <Button
                href='/privacy'
                color='inherit'
                sx={{
                  display: 'block',
                  '&:hover': {
                    color: '#ff9900'
                  }
                }}
              >
                Privacy and Other Policy
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Typography variant='body2' sx={{ mt: 3, textAlign: 'center' }}>
          © {currentYear} Dairy News 7x7. All Rights Reserved.
        </Typography>
      </Box>
    </>
  )
}

export default Footer
