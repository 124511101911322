import React, { useState, useEffect, useMemo } from 'react';
import './Layout.scss';
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Button, Typography, Grid, Chip, useMediaQuery } from '@mui/material';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#5c97c3',
    },
    dark: {
      main: '#111111',
    },
    background: {
      default: '#000000',
      paper: '#000000',
      warning: '#f5c44e'
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1100,
      xl: 1536,
    },
  }
});

const Layout = () => {
  const [loginStatus, setLoginStatus] = useState("");
  const [init, setInit] = useState(false);

  useEffect(() => {
    const loginStat = localStorage.getItem("course");
    if (loginStat) {
      setLoginStatus(loginStat);
    }

    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const callLoginAPi = (course) => {
    localStorage.setItem("course", course);
    setLoginStatus(course);
  };

  const particlesOptions = useMemo(() => ({
    background: {
      color: { value: "transparent" },
    },
    particles: {
      number: { value: 30 },
      size: { value: { min: 1, max: 5 } },
      color: { value: '#f5c44e' },
      links: {
        color: '#f5c44e',
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      move: {
        enable: true,
        speed: 6,
        direction: "none",
        outModes: { default: "bounce" },
      },
    },
    detectRetina: true,
  }), []);
  const isMobile = useMediaQuery('(max-width: 600px)')
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ background: "#eeeeee", position: "fixed", width: "100%", overflowX: "hidden", height: "100vH" }}>
        <Header />
        {!isMobile && (

          <div style={{ marginTop: "70px" }}>
          </div>
        )}
        <Outlet />
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

Layout.propTypes = {};

export default Layout;
