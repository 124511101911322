import React from 'react'
import './Settings.scss'
import { useAuth } from '../../services/authContext'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import FolderIcon from '@mui/icons-material/Folder'
import LogoutIcon from '@mui/icons-material/Logout'
import GavelIcon from '@mui/icons-material/Gavel'
import PolicyIcon from '@mui/icons-material/Policy'
import GoogleTranslate from '../GoogleTranslate/GoogleTranslate'
import Divider from '@mui/material/Divider'
import { useNavigate } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';


const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#5c97c3'
    },
    background: {
      default: '#f5c44e',
      paper: '#f5c44e'
    }
  }
})

const Settings = () => {
  const navigate = useNavigate()
  const { loginStatus, setLoginStatus } = useAuth()

  React.useEffect(() => {
    const loginStat = localStorage.getItem('loginCred')

    if (loginStat) {
      setLoginStatus(JSON.parse(loginStat))
    }
  }, [setLoginStatus])

  const logOut = () => {
    localStorage.removeItem('loginCred');
    googleLogout();
    navigate('/')
    //window.location.reload()
  }

  const tnc = () => {
    navigate('/terms')
  }

  const pp = () => {
    navigate('/privacy')
  }

  return (
    <>
      {loginStatus.token ? (
        <div className='Settings perfect-center' data-testid='Settings'>
          <List>
            <Divider variant='inset' sx={{ marginLeft: '0px' }} />
            <ListItem onClick={logOut}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <LogoutIcon color='primary' />
              </ListItemIcon>
              <ListItemText primary='Log Out' sx={{ fontWeight: 700 }} />
            </ListItem>
            <Divider variant='inset' sx={{ marginLeft: '0px' }} />
            <ListItem onClick={tnc}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <GavelIcon color='primary' />
              </ListItemIcon>
              <ListItemText primary='Terms' sx={{ fontWeight: 700 }} />
            </ListItem>
            <Divider variant='inset' sx={{ marginLeft: '0px' }} />
            <ListItem onClick={pp}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <PolicyIcon color='primary' />
              </ListItemIcon>
              <ListItemText
                primary='Privacy and Other Policies'
                sx={{ fontWeight: 700 }}
              />
            </ListItem>
            <Divider variant='inset' sx={{ marginLeft: '0px' }} />
          </List>
        </div>
      ) : (
        <div className='Settings perfect-center' data-testid='Settings'>
          <List>
            <Divider variant='inset' sx={{ marginLeft: '0px' }} />
            <Divider variant='inset' sx={{ marginLeft: '0px' }} />
            <ListItem onClick={tnc}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <GavelIcon color='primary' />
              </ListItemIcon>
              <ListItemText primary='Terms' sx={{ fontWeight: 700 }} />
            </ListItem>
            <Divider variant='inset' sx={{ marginLeft: '0px' }} />
            <ListItem onClick={pp}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <PolicyIcon color='primary' />
              </ListItemIcon>
              <ListItemText
                primary='Privacy and Other Policies'
                sx={{ fontWeight: 700 }}
              />
            </ListItem>
            <Divider variant='inset' sx={{ marginLeft: '0px' }} />
          </List>
        </div>
      )}
    </>
  )
}

export default Settings
