import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Divider,
} from '@mui/material';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { client_id } from '../../constants/app.constant';
import { LoginApi } from '../../services/apis';
import { useAuth } from '../../services/authContext';

const Profile = () => {
  const { loginStatus, setLoginStatus } = useAuth();
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const loginStat = localStorage.getItem('loginCred');
    if (loginStat) {
      const parsedLoginStat = JSON.parse(loginStat);
      setLoginStatus(parsedLoginStat);
    } else {
      setLoginStatus([]);
    }
  }, [setLoginStatus]);

  // Handle Manual Login
  const handleManualLogin = async (e) => {
    e.preventDefault();
    try {
      setError('');
      const payload = {
        email: email,
        password: password,
      };
      const response = await LoginApi(payload);
      localStorage.setItem('loginCred', JSON.stringify(response));
      setLoginStatus(response);
    } catch (err) {
      setError('Invalid email or password. Please try again.');
      console.error('Manual Login Error:', err);
    }
  };

  // Handle Google Login
  const handleGoogleLogin = async (googleResponse) => {
    try {
      setError('');
      const payload = {
        token: googleResponse.credential,
      };
      const response = await LoginApi(payload);
      localStorage.setItem('loginCred', JSON.stringify(response));
      setLoginStatus(response);
    } catch (err) {
      setError('Login failed. Please try again.');
      console.error('Google Login Error:', err);
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f4f6f8',
        padding: '20px',
      }}
    >
      {!loginStatus.token ? (
        <Card
          sx={{
            width: '100%',
            maxWidth: '400px',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            backgroundColor: '#fff',
          }}
        >
          <CardContent sx={{ textAlign: 'center' }}>
            <Typography variant="h5" sx={{ marginBottom: '16px' }}>
              Login
            </Typography>

            <form onSubmit={handleManualLogin}>
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ marginBottom: '16px' }}
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ marginBottom: '16px' }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ marginBottom: '8px' }}
              >
                Submit
              </Button>
            </form>

            <Typography variant="body2" sx={{ marginBottom: '16px' }}>
              Don't have an account? <a href="/register">Create new account</a>
            </Typography>

            <Divider sx={{ margin: '16px 0' }}>OR</Divider>

            <GoogleOAuthProvider clientId={client_id}>
              <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={() => setError('Login failed. Please try again.')}
              />
            </GoogleOAuthProvider>

            {error && (
              <Typography variant="body2" color="error" sx={{ marginTop: '8px' }}>
                {error}
              </Typography>
            )}
          </CardContent>
        </Card>
      ) : (
        <Typography variant="h6">You are logged in!</Typography>
      )}
    </Box>
  );
};

export default Profile;
