import React from 'react'
import PropTypes from 'prop-types'
import './AdminDashBoard.scss'
import { Grid, Box, Card, CardContent, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import {
  ListProductApi,
  ListSubjectApi,
  ListFeesApi,
  ListTeachersApi,
  ListStudentsApi
} from '../../services/apis'
import { useAuth } from '../../services/authContext'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PersonIcon from '@mui/icons-material/Person'
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl'
import SchoolIcon from '@mui/icons-material/School'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import EventNoteIcon from '@mui/icons-material/EventNote'
import PunchClockIcon from '@mui/icons-material/PunchClock'

const AdminDashBoard = () => {
  const { loginStatus, setLoginStatus } = useAuth()
  const [listOfUsers, setListOfUsers] = React.useState([])
  const [listOfSubjects, setListOfSubjects] = React.useState([])
  const [listOfFees, setListOfFees] = React.useState([])
  const [listOfTeacher, setListOfTeacher] = React.useState([])
  const [listOfStudent, setListOfStudent] = React.useState([])

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: { main: '#e48c48' },
      warning: { main: '#e48c48' },
      background: {
        default: '#f5f5f5', // Light grey background
        paper: '#e0e0e0' // Slightly darker grey for paper components
      }
    }
  })

  React.useEffect(() => {
    const loginStat = localStorage.getItem('loginCred')
    if (loginStat) {
      const parsedLoginStat = JSON.parse(loginStat)
      setLoginStatus(parsedLoginStat)

      // Fetching products if user is a superuser
      if (parsedLoginStat.user && parsedLoginStat.user.is_superuser) {
        const fetchListProducts = async () => {
          try {
            const response = await ListProductApi(parsedLoginStat.token)
            setListOfUsers(response)
          } catch (error) {
            console.error('Error fetching users:', error)
          }
        }
        fetchListProducts()
      }

      const fetchListSubjects = async () => {
        try {
          const response = await ListSubjectApi(parsedLoginStat.token)
          setListOfSubjects(response)
        } catch (error) {
          console.error('Error fetching subjects:', error)
        }
      }

      fetchListSubjects()

      const fetchListFees = async () => {
        try {
          const response = await ListFeesApi(parsedLoginStat.token)
          setListOfFees(response)
          console.log(listOfFees)
        } catch (error) {
          console.error('Error fetching fees:', error)
        }
      }

      fetchListFees()

      const fetchListTeacher = async () => {
        try {
          const authToken = localStorage.getItem('loginCred')
          const response = await ListTeachersApi(
            JSON.stringify({ adminToken: JSON.parse(authToken).token })
          )
          setListOfTeacher(response)
          console.log(listOfTeacher)
        } catch (error) {
          console.error('Error fetching teachers:', error)
        }
      }

      fetchListTeacher()

      const fetchListStudent = async () => {
        try {
          const response = await ListStudentsApi(parsedLoginStat.token)
          setListOfStudent(response)
          console.log(listOfStudent)
        } catch (error) {
          console.error('Error fetching students:', error)
        }
      }

      fetchListStudent()
    }
  }, [setLoginStatus]) // Only depend on setLoginStatus

  return (
    <ThemeProvider theme={theme}>
      <div className='AdminDashBoard' data-testid='AdminDashBoard'>
        <Box sx={{ flexGrow: 1, paddingTop: '20px' }}>
          <Typography
            variant='h6'
            component='h6'
            sx={{
              textAlign: 'left',
              color: '#2c3e50',
              padding: '10px 20px',
              fontWeight: '600',
              letterSpacing: '1px',
              borderBottom: '2px solid #bdc3c7'
            }}
          >
            Administrative Section
          </Typography>

          <Grid
            container
            justifyContent='center'
            sx={{ marginTop: '8px' }}
            spacing={2}
            rowSpacing={3}
          >
            {/* Classes Section */}
            <Grid item xs={4} sm={6} md={4} lg={3}>
              <Link
                to='/class'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '28px',
                    ':hover': { backgroundColor: '#e9eaeb' },
                    fontSize: { xs: 60, sm: 70 },
                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <SchoolIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, marginBottom: '-10px' },
                          color: '#8249db'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',
                        fontWeight: 'bold',
                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfUsers.length}{' '}
                      {listOfUsers.length > 1 ? 'Classes' : 'Class'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            {/* Subjects Section */}
            <Grid item xs={4} sm={6} md={4} lg={3}>
              <Link
                to='/subject'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '28px',
                    ':hover': { backgroundColor: '#e9eaeb' },
                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <CalendarTodayIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, marginBottom: '-10px' },
                          color: '#44882f'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',
                        fontWeight: 'bold',
                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfSubjects.length}{' '}
                      {listOfSubjects.length > 1 ? 'Subjects' : 'Subject'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            {/* Timetables Section */}
            <Grid item xs={4} sm={6} md={4} lg={3}>
              <Link
                to='/timetable'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '28px',
                    ':hover': { backgroundColor: '#e9eaeb' },
                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <PunchClockIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, marginBottom: '-10px' },
                          color: '#d40000'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',
                        fontWeight: 'bold',
                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfUsers.length}{' '}
                      {listOfUsers.length > 1 ? 'Timetables' : 'Timetable'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            {/* Homework Section */}
            <Grid item xs={4} sm={6} md={4} lg={3}>
              <Link
                to='/studentHomework'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '28px',
                    ':hover': { backgroundColor: '#e9eaeb' },
                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <EventNoteIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, marginBottom: '-10px' },
                          color: '#2177eb'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',
                        fontWeight: 'bold',
                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfSubjects.length}{' '}
                      {listOfSubjects.length > 1 ? 'Homeworks' : 'Homework'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            {/* Fees Section */}
            <Grid item xs={4} sm={6} md={4} lg={3}>
              <Link
                to='/fees'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '28px',
                    ':hover': { backgroundColor: '#e9eaeb' },
                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <AttachMoneyIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, marginBottom: '-10px' },
                          color: '#d40000'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',
                        fontWeight: 'bold',
                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfSubjects.length}{' '}
                      {listOfSubjects.length > 1 ? 'Fees' : 'Fee'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, paddingTop: '30px' }}>
          <Typography
            variant='h6'
            component='h6'
            sx={{
              textAlign: 'left',
              color: '#2c3e50',
              padding: '10px 20px',
              fontWeight: '600',
              letterSpacing: '1px',
              borderBottom: '2px solid #bdc3c7'
            }}
          >
            Teacher Section
          </Typography>

          <Grid
            container
            justifyContent='center'
            spacing={2}
            sx={{ marginTop: '20px' }}
          >
            {/* Teacher Grid */}
            <Grid
              item
              xs={4}
              sm={6}
              md={4}
              lg={3}
              sx={{ order: { xs: 1, sm: 1, md: 4 } }}
            >
              <Link
                to='/teacher'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '20px',
                    ':hover': { backgroundColor: '#e9eaeb' },

                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <PersonIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, md: 70 },
                          color: '#0075FF'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',
                        fontWeight: 'bold',
                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfTeacher.length}{' '}
                      {listOfTeacher.length > 1 ? 'Teachers' : 'Teacher'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            {/* Teacher Attendance Grid */}
            <Grid
              item
              xs={4}
              sm={6}
              md={4}
              lg={3}
              sx={{ order: { xs: 1, sm: 1, md: 4 } }}
            >
              <Link
                to='/teacherAttendance'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '20px',
                    ':hover': { backgroundColor: '#e9eaeb' },

                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <EventNoteIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, md: 70 },
                          color: '#EE3A7C'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',
                        fontWeight: 'bold',
                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfTeacher.length}{' '}
                      {listOfTeacher.length > 1
                        ? 'Teachers Attendance'
                        : 'Teacher Attendance'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </div>
    </ThemeProvider>
  )
}

AdminDashBoard.propTypes = {}

export default AdminDashBoard
