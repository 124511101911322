import React, { useState, useEffect, useRef } from 'react'
import './Initial.scss' // Define animation styles here
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  TextField,
  Grid,
  Paper,
  Avatar
} from '@mui/material'
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions
} from 'react-swipeable-list'
import { Helmet } from 'react-helmet'
import { Facebook, Twitter, LinkedIn, Instagram } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import 'react-swipeable-list/dist/styles.css'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import CommentIcon from '@mui/icons-material/Comment'
import ShareIcon from '@mui/icons-material/Share'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useAuth } from '../../services/authContext'
import { Link } from 'react-router-dom'
import { format } from 'date-fns' // Import date-fns for formatting dates
import {
  FetchNewsApi,
  FetchMediaApi,
  FetchPostsBySlugApi,
  FetchAdsApi
} from '../../services/apis'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Data from '../Data/Data'
import DOMPurify from "dompurify";
import ImageWithLoader from '../ImageWithLoader/ImageWithLoader'
import {
  AppBar,
  Toolbar,
  MenuItem,
  Select,
  Divider,
  Stack,
  Chip
} from '@mui/material'
import { ExpandMore, Language } from '@mui/icons-material'
import { YouTube } from '@mui/icons-material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Container from '@mui/material/Container'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import GlobalNews from '../GlobalNews/GlobalNews'
import IndianNews from '../IndianNews/IndianNews'
import WeeklyNews from '../WeeklyNews/WeeklyNews'
import BLogNews from '../BlogNews/BlogNews'

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000
}
let j = 0
let i = 0

const Initial = () => {
  const [cards, setCards] = useState([])
  const [featuredImages, setFeaturedImages] = useState([])
  const [featuredImages2, setFeaturedImages2] = useState([])
  const [currentCardIndex, setCurrentCardIndex] = useState(0)
  const [animationDirection, setAnimationDirection] = useState('')
  const cardContentRef = useRef(null)
  const [isLiked, setIsLiked] = useState(false)
  const { loginStatus, setLoginStatus } = useAuth()
  const [error, setError] = useState('')
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [showSlugFlag, setShowSlugFlag] = useState(true)
  const [comments, setComments] = useState([])
  const [loadingComments, setLoadingComments] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState('indian-news')
  const location = useLocation()
  const [newComment, setNewComment] = useState('')
  const [commentSubmitting, setCommentSubmitting] = useState(false)
  const [latestNews, setLatestNews] = useState([])
  const [fetchPart2, setFetchPart2] = useState([])
  const [pageTitle, setPageTitle] = useState('Dairy News 7X7: Latest Global Dairy Industry Updates and Insights');
  const [adsData, setAdsData] = useState({});

  const styles = {
    column: {
      width: '1150px',
      height: '510px',
      border: '1px solid #ccc',
      padding: '0px',

      backgroundColor: '#f9f9f9',
      borderRadius: '5px',
      marginTop: '19px',
      marginBottom: '20px'
    },
    ad: {
      marginBottom: '20px',
      textAlign: 'center'
    },
    image: {
      maxWidth: 'auto',
      height: '400px',

      marginTop: '-10px',
      paddingBottom: '103px'
    }
  }

  const follow_styles = {
    column: {
      width: '400px',
      height: '550px',
      border: '1px solid #ccc',
      padding: '0px',
      marginLeft: '90px',
      backgroundColor: '#f9f9f9',
      borderRadius: '5px',
      marginTop: '19px',
      marginBottom: '20px'
    },
    ad: {
      marginBottom: '20px',
      textAlign: 'center'
    },
    image: {
      maxWidth: 'auto',
      height: '400px',

      marginTop: '-10px',
      paddingBottom: '103px'
    }
  }

  const latest_styles = {
    column: {
      width: '650px',
      height: '630px',
      border: '1px solid #ccc',
      padding: '0px',

      backgroundColor: '#f9f9f9',
      borderRadius: '5px',
      marginTop: '19px',
      marginBottom: '20px'
    },
    ad: {
      marginBottom: '20px',
      textAlign: 'center'
    },
    image: {
      maxWidth: 'auto',
      height: '400px',

      marginTop: '-10px',
      paddingBottom: '103px'
    }
  }

  const ads = [
    { id: 1, image: 'ad1.jpg', alt: 'Ad 1' },
    { id: 2, image: 'ad2.jpg', alt: 'Ad 2' },
    { id: 3, image: 'ad3.jpg', alt: 'Ad 3' }
  ]
  const columnStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '200px',
    backgroundColor: '#f5f5f5',
    padding: '10px',
    border: '1px solid #ddd'
  }

  const imageStyle = {
    marginBottom: '20px',
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
  }
  const iimageStyle = {
    width: '100%', // Makes the image responsive
    maxWidth: '650px', // Limits the maximum width
    height: 'auto', // Maintains the aspect ratio
    borderRadius: '8px', // Adds rounded corners
    marginLeft: '740px',
    margin: '10px auto', // Centers the image and adds spacing
    display: 'block', // Ensures proper alignment for block-level content
    marginTop: '-1000px',
    marginBottom: '1000px'
  }

  const topics = [
    'ReactJS is awesome!',
    'Material-UI makes styling easy!',
    'Develop dynamic apps quickly!',
    'Stay updated with the latest tech!',
    'Explore the world of web development!'
  ]

  const savedVideos = [
    {
      id: 1,
      title: 'CII-EDF study:actionable steps to curb dairy emissions',
      thumbnail: '/placeholder.svg?height=300&width=400',
      videoId: 'xyz123'
    },
    {
      id: 2,
      title: 'Artificial Insemination in Dairy Farming',
      thumbnail: '/placeholder.svg?height=200&width=300',
      videoId: 'abc456'
    },
    {
      id: 3,
      title: 'Silage Sex-Sorted Semen',
      thumbnail: '/placeholder.svg?height=200&width=300',
      videoId: 'def789'
    },
    {
      id: 4,
      title: 'Embryo Transfer Techniques',
      thumbnail: '/placeholder.svg?height=200&width=300',
      videoId: 'ghi012'
    }
  ]

  const savedNews = [
    {
      id: 1,
      title:
        'India ranked 10th out of 190 countries in antibiotic usage in meat',
      date: 'December 15, 2024',
      image: '/placeholder.svg?height=100&width=150'
    },
    {
      id: 2,
      title: 'Inter Dairy 2024 urges double-digit milk procurement growth',
      date: 'December 8, 2024',
      image: '/placeholder.svg?height=100&width=150'
    },
    {
      id: 3,
      title:
        'Is buffalo milks share truly down 16% while desi cow milk rises by 45%?',
      date: 'December 1, 2024',
      image: '/placeholder.svg?height=100&width=150'
    }
  ]

  const data = [
    {
      id: 1,
      category: 'Business',
      date: 'Jan 01, 2045',
      title: 'Lorem ipsum dolor sit amet elit...',
      imagePath: '/img_frontend/tree.jpg' // Replace with your actual image path
    },
    {
      id: 2,
      category: 'Business',
      date: 'Jan 01, 2045',
      title: 'Lorem ipsum dolor sit amet elit...',
      imagePath: '/img_frontend/tree.jpg' // Replace with your actual image path
    },
    {
      id: 3,
      category: 'Business',
      date: 'Jan 01, 2045',
      title: 'Lorem ipsum dolor sit amet elit...',
      imagePath: '/img_frontend/tree.jpg' // Replace with your actual image path
    },
    {
      id: 4,
      category: 'Business',
      date: 'Jan 01, 2045',
      title: 'Lorem ipsum dolor sit amet elit...',
      imagePath: '/img_frontend/tree.jpg' // Replace with your actual image path
    }
  ]

  const datal = [
    {
      id: 1,
      image: '/img_frontend/tree.jpg', // Replace with actual image URL
      category: 'Business',
      date: 'Jan 01, 2045',
      title: 'Lorem Ipsum Dolor Sit Amet Elit...',
      description:
        'Dolor lorem eos dolor duo et eirmod sea. Dolor sit magna rebum clita rebum dolor stet amet justo',
      author: 'John Doe',
      avatar: 'https://via.placeholder.com/40', // Replace with actual avatar URL
      views: 12345,
      comments: 123
    },
    {
      id: 2,
      image: '/img_frontend/tree.jpg', // Replace with actual image URL
      category: 'Business',
      date: 'Jan 01, 2045',
      title: 'Lorem Ipsum Dolor Sit Amet Elit...',
      description:
        'Dolor lorem eos dolor duo et eirmod sea. Dolor sit magna rebum clita rebum dolor stet amet justo',
      author: 'John Doe',
      avatar: 'https://via.placeholder.com/40', // Replace with actual avatar URL
      views: 12345,
      comments: 123
    }
  ]

  const socialMedia = [
    {
      name: 'Facebook',
      icon: <Facebook />,
      followers: '12,345 Fans',
      color: '#3b5998'
    },
    {
      name: 'Twitter',
      icon: <Twitter />,
      followers: '12,345 Followers',
      color: '#1da1f2'
    },
    {
      name: 'LinkedIn',
      icon: <LinkedIn />,
      followers: '12,345 Connects',
      color: '#0077b5'
    },
    {
      name: 'Instagram',
      icon: <Instagram />,
      followers: '12,345 Followers',
      color: '#e4405f'
    },
    {
      name: 'YouTube',
      icon: <YouTube />,
      followers: '12,345 Subscribers',
      color: '#ff0000'
    }
    // { name: "Vimeo", icon: <Vimeo />, followers: "12,345 Followers", color: "#1ab7ea" },
  ]

  // Handle opening and closing the drawer
  const toggleDrawer = (open, postid) => async () => {
    setIsDrawerOpen(open)
    if (open) {
      await fetchComments(postid)
    }
  }

  // Utility function to decode HTML entities
  const decodeHtml = html => {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.documentElement.textContent
  }

  const getPlainText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const fetchData = async formattedDate => {
    try {
      const postsPerPage = 10

      // Fetch new data
      const response = await FetchNewsApi({
        per_page: postsPerPage,
        before: formattedDate
      })

      if (!response) {
        throw new Error('Failed to fetch news from the server.')
      }

      const apiData = await response
      const newData = apiData || [] // Ensure fallback to an empty array if no news is available

      if (!newData.length) {
        console.log('No new data fetched')
        return // Exit if there's no new data to append
      }

      // Filter out duplicates
      const existingIds = new Set(cards.map(card => card.id))
      const filteredNewData = newData.filter(item => !existingIds.has(item.id))

      // Fetch featured images for the new data
      const imagePromises = filteredNewData.map(async post => {
        if (post.featured_media) {
          try {
            const mediaResponse = await FetchMediaApi({
              featured_media_id: post.featured_media
            })
            return mediaResponse?.source_url || null // Use `source_url` or fallback to `null`
          } catch (error) {
            console.error(`Error fetching media for post ${post.id}:`, error)
            return null
          }
        }
        return null // No featured_media
      })

      const newImages = await Promise.all(imagePromises)

      // Append new data and images to existing state
      setCards(prevCards => [...prevCards, ...filteredNewData])
      setFeaturedImages(prevImages => [...prevImages, ...newImages])

      console.log('New data and images fetched and appended:', filteredNewData)
    } catch (error) {
      setError('Error fetching posts.')
      console.error('Error in fetchData:', error)
    }
  }

  const fetchComments = async postid => {
    setLoadingComments(true)
    try {
      const response = await fetch(
        `https://back.dairynews7x7.com/wp-json/wp/v2/comments?post=${postid}`
      )
      if (!response.ok) {
        throw new Error('Failed to fetch comments')
      }
      const data = await response.json()
      setComments(data)
    } catch (error) {
      console.error('Error fetching comments:', error)
    } finally {
      setLoadingComments(false)
    }
  }

  // Fetch data from API and handle login status
  useEffect(() => {
    setPageTitle('Dairy News 7X7: Latest Global Dairy Industry Updates and Insights');
    const fetchInitialData = async () => {
      const today = new Date()
      const formattedDate = today.toISOString()
      const isNewsRoute = location.pathname.includes('/news')
      const slug = isNewsRoute ? location.pathname.split('/news/')[1] : null

      try {
        let slugCard = []
        if (slug) {
          const slugResponse = await FetchPostsBySlugApi({ slug })
          const slugData = await slugResponse
          if (slugData?.length > 0) {
            const newsTitle = slugData[0]?.title?.rendered || 'News'
            setPageTitle(newsTitle) // Update the page title
          } else {
            setPageTitle('News Not Found')
          }
          window.scrollTo({ top: 0, behavior: 'smooth' })
          slugCard = slugData.length > 0 ? [slugData[0]] : []
        }

        const generalResponse = await FetchNewsApi({
          per_page: 10,
          before: formattedDate
        })

        const generalData = generalResponse

        // Ensure slug-based card is not duplicated
        const filteredGeneralData = generalData.filter(
          card => !slugCard.some(slugCard => slugCard.id === card.id)
        )

        // Combine slug-based card and general data
        const combinedCards = [...slugCard, ...filteredGeneralData]
        setCards(combinedCards)
        setLatestNews(combinedCards.slice(0, 4))

        // Fetch featured images progressively
        const images = []
        for (const post of combinedCards) {
          if (post.featured_media) {
            try {
              const mediaResponse = await FetchMediaApi({
                featured_media_id: post.featured_media
              })

              const imageUrl = mediaResponse?.source_url || null // Use source_url or fallback to null
              images.push(imageUrl) // Add the image to the list
              setFeaturedImages([...images]) // Update the state progressively
            } catch (error) {
              console.error(`Error fetching media for post ${post.id}:`, error)
              images.push(null) // Add null for failed fetch
              setFeaturedImages([...images]) // Update the state progressively
            }
          } else {
            images.push(null) // No featured_media
            setFeaturedImages([...images]) // Update the state progressively
          }
        }

        console.log('Fetched Images:', images) // Debugging

        // Handle login status
        const loginStat = localStorage.getItem('loginCred')
        if (loginStat) {
          const parsedLoginStat = JSON.parse(loginStat)
          setLoginStatus(parsedLoginStat)
        } else {
          setLoginStatus([])
        }
      } catch (error) {
        setError('Error fetching data.')
        console.error(error)
      }
    }

    const fetchAndDisplayAds = async () => {
      try {
        const response = await FetchAdsApi();
        if (response.status === 'success') {
          setAdsData(response.data);
        } else {
          console.error('Error fetching ads:', response.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    // Call the function
    fetchAndDisplayAds();
    fetchInitialData()
    fetchDataPart2()
  }, [location.pathname, setLoginStatus])

  // Handle swipe left (previous card)
  const handleSwipeLeft = () => {
    setAnimationDirection('left');
    setCurrentCardIndex(prevIndex =>
      prevIndex === 0 ? cards.length - 1 : prevIndex - 1
    )

    if (cardContentRef.current) {
      cardContentRef.current.scrollTop = 0
    }

    if (cards.length > 0) {
      setShowSlugFlag(false);
      const nextTitle = cards[currentCardIndex === 0 ? cards.length - 1 : currentCardIndex - 1]
        ?.title.rendered;
      const nextSlug =
        cards[currentCardIndex === 0 ? cards.length - 1 : currentCardIndex - 1]
          ?.slug
      if (nextSlug) {
        window.history.pushState(null, '', `/news/${nextSlug}`)
      }

      if (nextTitle) {
        setPageTitle(`${nextTitle}`);
      }
    }
  }

  const processShortcodes = (htmlContent) => {
    let formattedContent = htmlContent; // Create a copy to modify  
    // Replace YouTube [embed] shortcode
    formattedContent = formattedContent.replace(
      /\[embed\]https?:\/\/(?:www\.)?youtube\.com\/watch\?v=([\w-]+)\[\/embed\]/g,
      `<div class="youtube-container">
          <iframe width="100%" height="315" 
            src="https://www.youtube.com/embed/$1" 
            title="YouTube video" frameBorder="0" allowFullScreen>
          </iframe>
        </div>`
    );    
  
    // Replace [gallery] shortcode (simplified)
    formattedContent = formattedContent.replace(
      /\[gallery.*ids="([\d,]+)".*?\]/g,
      `<div class="gallery-container">Gallery Placeholder for IDs: $1</div>`
    );
  
    // Replace [audio] shortcode
    formattedContent = formattedContent.replace(
      /\[audio\s+src="(.*?)"\]/g,
      `<audio controls><source src="$1" type="audio/mpeg">Your browser does not support the audio element.</audio>`
    );
  
    // Replace [video] shortcode
    formattedContent = formattedContent.replace(
      /\[video\s+src="(.*?)"\]/g,
      `<video controls width="100%"><source src="$1" type="video/mp4">Your browser does not support the video element.</video>`
    );

     // 🔹 Ensure paragraphs are wrapped properly
    formattedContent = formattedContent
    .split(/\n\s*\n/) // Split by double newlines (indicating separate paragraphs)
    .map((paragraph) => paragraph.trim().length > 0 ? `<p>${paragraph.trim()}</p>` : "")
    .join("");
  
    return formattedContent;
  };
  

  const baseUrl = window.location.origin // Dynamically gets the base URL

  const handleShare = async () => {
    const currentCard = cards[currentCardIndex];
    const currentImage = featuredImages[currentCardIndex]; // The background image
    const shareUrl = `${baseUrl}/share/${currentCard?.slug}`;
  
    // Update the Open Graph image dynamically
    if (currentImage) {
      let ogImageMeta = document.querySelector('meta[property="og:image"]');
      if (!ogImageMeta) {
        ogImageMeta = document.createElement("meta");
        ogImageMeta.setAttribute("property", "og:image");
        document.head.appendChild(ogImageMeta);
      }
      ogImageMeta.setAttribute("content", currentImage);
    }
  
    // Prepare share data
    const shareData = {
      title: currentCard?.title?.rendered || "Check this out!",
      text: currentCard?.excerpt?.rendered.replace(/<[^>]*>/g, "") || "Check out this amazing article!",
      url: shareUrl,
    };
  
    // Try to fetch and attach image file (for Web Share API with files)
    if (navigator.canShare && currentImage) {
      try {
        const response = await fetch(currentImage);
        const blob = await response.blob();
        const file = new File([blob], "shared-image.jpg", { type: blob.type });
  
        if (navigator.canShare({ files: [file] })) {
          shareData.files = [file];
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    }
  
    // Try sharing with Web Share API
    if (navigator.share) {
      try {
        await navigator.share(shareData);
        console.log("Shared successfully:", shareUrl);
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      // Fallback: Copy link manually
      alert(`Sharing is not supported in your browser. Copy this link: ${shareUrl}`);
    }
  };  

  const handleSwipeRight = async () => {
    setAnimationDirection('right')
    setCurrentCardIndex(prevIndex => (prevIndex + 1) % cards.length)

    if (cardContentRef.current) {
      cardContentRef.current.scrollTop = 0
    }

    if (cards.length > 0) {
      setShowSlugFlag(false)
      const nextTitle = cards[currentCardIndex === 0 ? cards.length + 1 : currentCardIndex + 1]
        ?.title.rendered;
      const nextSlug = cards[(currentCardIndex + 1) % cards.length]?.slug;
      if (nextSlug) {
        window.history.pushState(null, '', `/news/${nextSlug}`)
      }

      if (nextTitle) {
        setPageTitle(`${nextTitle}`);
      }
    }

    j += 1

    if (j === 5) {
      j = 0
      i -= 9
      const today = new Date()
      const targetDate = new Date(today)
      targetDate.setDate(today.getDate() + i)
      const formattedDate = targetDate.toISOString()

      try {
        // Fetch new data and append to existing state
        await fetchData(formattedDate)
      } catch (error) {
        console.error('Error fetching new data on swipe:', error)
      }
    }
  }

  const fetchDataPart2 = async () => {
    const today = new Date()
    const targetDate = new Date(today)
    targetDate.setDate(today.getDate() - 10)
    const formattedDate = targetDate.toISOString()

    // Fetch initial data
    const response = await FetchNewsApi({
      per_page: 10,
      before: formattedDate
    })

    setFetchPart2(response)

    // Fetch featured images progressively
    const images = []
    for (const post of response) {
      if (post.featured_media) {
        try {
          const mediaResponse = await FetchMediaApi({
            featured_media_id: post.featured_media
          })

          const imageUrl = mediaResponse?.source_url || null // Use source_url or fallback to null
          images.push(imageUrl) // Add the image to the list
          setFeaturedImages2([...images]) // Update the state progressively
        } catch (error) {
          console.error(`Error fetching media for post ${post.id}:`, error)
          images.push(null) // Add null for failed fetch
          setFeaturedImages2([...images]) // Update the state progressively
        }
      } else {
        images.push(null) // No featured_media
        setFeaturedImages2([...images]) // Update the state progressively
      }
    }

    console.log('Fetched Images:', images) // Debugging
  }

  // Reset animation state after the animation ends
  const handleAnimationEnd = () => {
    setAnimationDirection('')
  }

  // Decode the title before rendering
  const currentCard = cards[currentCardIndex]
  const currentImage = featuredImages[currentCardIndex] // Get the featured image for the current card
  const decodedTitle = currentCard?.title?.rendered
    ? decodeHtml(currentCard.title.rendered)
    : ''

  // Format the date using date-fns
  const formattedDate = currentCard?.date
    ? format(new Date(currentCard.date), 'MMMM dd, yyyy')
    : ''

  // Define swipeable actions
  const leadingActions = () => (
    <LeadingActions>
      <SwipeAction onClick={handleSwipeLeft}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <ArrowBackIcon sx={{ fontSize: '48px', color: '#999' }} />
        </Box>
      </SwipeAction>
    </LeadingActions>
  )

  const trailingActions = () => (
    <TrailingActions>
      <SwipeAction onClick={handleSwipeRight}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <ArrowForwardIcon sx={{ fontSize: '48px', color: '#999' }} />
        </Box>
      </SwipeAction>
    </TrailingActions>
  )

  const isMobile = useMediaQuery('(max-width: 1100px)')

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={decodedTitle} />
        <meta property="og:image" content={currentImage} />
      </Helmet>
      {isMobile ? (
        <Box sx={{ width: '100%', overflow: 'auto !important' }}>
          <SwipeableList sx={{ overflow: 'auto !important' }} threshold={0.01}>
            <SwipeableListItem
              leadingActions={leadingActions()}
              trailingActions={trailingActions()}
              sx={{ overflow: 'auto !important' }}
            >
              <div
                ref={cardContentRef}
                className={`card-container ${animationDirection === 'right'
                  ? 'slide-in-right'
                  : animationDirection === 'left'
                    ? 'slide-in-left'
                    : ''
                  }`}
                onAnimationEnd={handleAnimationEnd}
              >
                <Box
                  sx={{
                    background: currentImage
                      ? `url(${currentImage})`
                      : 'https://via.placeholder.com/300', // Fallback image
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '300px' // Adjust as needed
                  }}
                  className='current-image'
                ></Box>

                <Box
                  sx={{
                    background: '#ffffff',
                    margin: '-30px 0px',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0,0,0,1)',
                    minHeight: '100vH',
                    textAlign: 'left',
                    overflow: 'auto !important'
                  }}
                >
                  <Typography
                    variant='h5'
                    component='div'
                    sx={{
                      padding: '15px',
                      boxShadow: 'inset 0px 0px 10px rgba(0,0,0,0.1)'
                    }}
                  >
                    {decodedTitle}
                  </Typography>

                  {/* Display formatted date */}
                  <Typography
                    sx={{
                      mt: 1,
                      fontSize: 14,
                      color: 'text.secondary',
                      padding: '0px 15px'
                    }}
                  >
                    {formattedDate}
                  </Typography>

                  {/* Render HTML content safely using `dangerouslySetInnerHTML` */}
                  <Typography
                    sx={{ padding: '0px 15px', marginBottom: '150px' }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: currentCard?.content?.rendered
                          ? processShortcodes(currentCard.content.rendered)
                          : "Loading..."
                      }}
                    />
                  </Typography>
                </Box>
              </div>
            </SwipeableListItem>
          </SwipeableList>

          {/* Bottom Action Bar */}
          <Box
            sx={{
              background: '#000000',
              margin: '15px',
              padding: '15px',
              boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
              borderRadius: '38px',
              position: 'fixed',
              width: '91%',
              zIndex: 100,
              bottom: '64px',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            {loginStatus.token ? (
              <>
                {/* Like Button *
                <IconButton
                  aria-label='like'
                  onClick={() => setIsLiked(!isLiked)}
                  sx={{ color: isLiked ? '#1976d2' : '#757575' }}
                >
                  {isLiked ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />}
                  <Typography variant='caption' sx={{ marginLeft: '4px' }}>
                    {isLiked ? 'Liked' : 'Like'}
                  </Typography>
                </IconButton>

                {/* Comment Button *
                <IconButton
                  aria-label='comment'
                  onClick={toggleDrawer(true, currentCard?.id)}
                  sx={{ color: '#4caf50' }}
                >
                  <CommentIcon />
                  <Typography variant='caption' sx={{ marginLeft: '4px' }}>
                    Comment
                  </Typography>
                </IconButton>

                {/* Share Button */}
                <IconButton
                  aria-label='share'
                  onClick={handleShare}
                  sx={{ color: '#f50057' }}
                >
                  <ShareIcon />
                  <Typography variant='caption' sx={{ marginLeft: '4px' }}>
                    Share Now
                  </Typography>
                </IconButton>

                <Drawer
                  anchor='bottom'
                  open={isDrawerOpen}
                  onClose={toggleDrawer(false, currentCard?.id)}
                >
                  <Box
                    sx={{
                      padding: '16px',
                      height: '50vh',
                      overflow: 'auto',
                      backgroundColor: '#333' // Dark background for contrast
                    }}
                  >
                    <Typography
                      variant='h6'
                      sx={{ marginBottom: '16px', color: 'white' }}
                    >
                      Comments
                    </Typography>
                    {loadingComments ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%'
                        }}
                      >
                        <CircularProgress color='inherit' />
                      </Box>
                    ) : comments.length > 0 ? (
                      <List>
                        {comments.map(comment => (
                          <ListItem key={comment.id} alignItems='flex-start'>
                            <ListItemText
                              primary={comment.author_name}
                              secondary={comment.content.rendered}
                              primaryTypographyProps={{
                                style: { color: 'white' }
                              }}
                              secondaryTypographyProps={{
                                style: { color: 'white' }
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Typography sx={{ color: 'white' }}>
                        No comments available.
                      </Typography>
                    )}
                    {/* Add Comment Section */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '16px'
                      }}
                    >
                      <TextField
                        variant='outlined'
                        fullWidth
                        placeholder='Write a comment...'
                        value={newComment}
                        onChange={e => setNewComment(e.target.value)}
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '8px',
                          marginBottom: '8px'
                        }}
                      />
                      <Button
                        variant='contained'
                        color='primary'
                        disabled={commentSubmitting}
                        sx={{ alignSelf: 'flex-end' }}
                      >
                        {commentSubmitting ? 'Submitting...' : 'Add Comment'}
                      </Button>
                    </Box>
                  </Box>
                </Drawer>
              </>
            ) : (
              <Link
                to='/profile'
                style={{
                  textDecoration: 'none',
                  color: '#000000',
                  fontWeight: 700
                }}
              >
                Login to Share News and get latest news on Email
              </Link>
            )}
          </Box>
        </Box>
      ) : (
        <>
             {/* -------------start here only------------- */}
          <>
            {/* Market Reports Section */}
            {!(
              location.pathname.includes("/news/") &&
              location.pathname.split("/news/")[1]
            ) ? (
              <div className="container-fluid py-4">
                <div className="container-fluid">
                  {/* Header */}
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <a href="#" style={{ color: "#0088cc" }}>
                      <i className="fas fa-arrow-right"></i>
                    </a>
                  </div>

                  <div className="row">
                    {/* Main Large News - Left Side */}
                    <div className='section-title mb-0 col-12'>
                      <h4 className='m-0 text-uppercase font-weight-bold'>
                        Up-to-date market reports
                      </h4>
                    </div>
                    <div className="col-12 col-lg-7 p-2 bg-white">
                      {cards && cards.length > 0 && (
                        <div className="cursor-pointer bg-white">
                          <ImageWithLoader
                            className="img-fluid w-100"
                            src={featuredImages?.[0]}
                            alt="Featured News"
                            style={{ height: "400px", objectFit: "cover" }}
                          />
                          <div style={{ padding: "20px" }}>
                            <span
                              style={{
                                color: "#dc3545",
                                fontSize: "0.8rem",
                                fontWeight: "600",
                                textTransform: "uppercase",
                                display: "block",
                                marginBottom: "8px",
                                textAlign: "left",
                              }}
                            >
                              {cards[0]?.categories?.[0]}
                            </span>
                            <span
                              style={{
                                color: "#666",
                                fontSize: "0.85rem",
                                display: "block",
                                marginBottom: "12px",
                                textAlign: "left",
                              }}
                            >
                              {cards[0]?.date &&
                                new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "short",
                                  day: "2-digit",
                                }).format(new Date(cards[0].date))}
                            </span>
                            <h5
                              style={{
                                margin: 0,
                                fontWeight: "600",
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                textAlign: "left",
                              }}
                              onClick={() => {
                                window.location.href =
                                  "/news/" + cards[0]?.slug;
                              }}
                            >
                              {cards[0]?.title?.rendered}
                            </h5>
                            
                            <div style={{textAlign: "left", color: "#111111", marginTop: "10px"}} onClick={() => {
                                window.location.href =
                                  "/news/" + cards[0]?.slug;
                              }}>
                              {currentCard?.content?.rendered
                                ? getPlainText(processShortcodes(currentCard.content.rendered)).slice(0, 400)
                                : "Loading..."} <a>...Read More</a>
                            </div>

                          </div>
                        </div>
                      )}
                    </div>

                    {/* Right Side News Grid */}
                    <div className="col-12 col-lg-5 bg-white">
                      <div className="row">
                        {/* Top Two Medium Cards */}
                        {cards &&
                          cards.slice(1, 3).map(
                            (item, index) =>
                              item && (
                                <div className="col-6 mb-4 bg-white p-2" key={index}>
                                  <div className="cursor-pointer">
                                    <ImageWithLoader
                                      className="img-fluid w-100"
                                      src={featuredImages?.[index + 1]}
                                      alt="News"
                                      style={{
                                        height: "160px",
                                        objectFit: "cover",
                                      }}
                                    />
                                    <div style={{ padding: "15px 0" }}>
                                      <span
                                        style={{
                                          color: "#dc3545",
                                          fontSize: "0.75rem",
                                          fontWeight: "600",
                                          textTransform: "uppercase",
                                          display: "block",
                                          marginBottom: "6px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {item?.categories?.[0]}
                                      </span>
                                      <span
                                        style={{
                                          color: "#666",
                                          fontSize: "0.8rem",
                                          display: "block",
                                          marginBottom: "8px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {item?.date &&
                                          new Intl.DateTimeFormat("en-US", {
                                            year: "numeric",
                                            month: "short",
                                            day: "2-digit",
                                          }).format(new Date(item.date))}
                                      </span>
                                      <h6
                                        style={{
                                          margin: 0,
                                          fontSize: "0.9rem",
                                          fontWeight: "600",
                                          lineHeight: "1.4",
                                          cursor: "pointer",
                                          textAlign: "left",
                                        }}
                                        onClick={() => {
                                          window.location.href =
                                            "/news/" + item?.slug;
                                        }}
                                      >
                                        {item?.title?.rendered}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}

                        {/* Bottom Small Cards */}
                        {cards &&
                          cards.slice(3, 6).map(
                            (item, index) =>
                              item && (
                                <div className="col-12 mb-3 bg-white p-2" key={index}>
                                  <div className="d-flex">
                                    <ImageWithLoader
                                      className="img-fluid"
                                      src={featuredImages?.[index + 3]}
                                      alt="News"
                                      style={{
                                        width: "100px",
                                        height: "80px",
                                        objectFit: "cover",
                                      }}
                                    />
                                    <div style={{ padding: "0 0 0 15px" }}>
                                      <span
                                        style={{
                                          color: "#dc3545",
                                          fontSize: "0.7rem",
                                          fontWeight: "600",
                                          textTransform: "uppercase",
                                          display: "block",
                                          marginBottom: "4px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {item?.categories?.[0]}
                                      </span>
                                      <span
                                        style={{
                                          color: "#666",
                                          fontSize: "0.75rem",
                                          display: "block",
                                          marginBottom: "6px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {item?.date &&
                                          new Intl.DateTimeFormat("en-US", {
                                            year: "numeric",
                                            month: "short",
                                            day: "2-digit",
                                          }).format(new Date(item.date))}
                                      </span>
                                      <h6
                                        style={{
                                          margin: 0,
                                          fontSize: "0.85rem",
                                          fontWeight: "600",
                                          lineHeight: "1.3",
                                          cursor: "pointer",
                                          textAlign: "left",
                                        }}
                                        onClick={() => {
                                          window.location.href =
                                            "/news/" + item?.slug;
                                        }}
                                      >
                                        {item?.title?.rendered}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {/* Trending Section */}
            <Box sx={{ width: '100%', margin: '0px' }}>
              <div className='container-fluid bg-dark py-3'>
                <div className='container-fluid'>
                  <div className='row align-items-center bg-dark'>
                    <div className='col-12'>
                      <div className='d-flex justify-content-between'>
                        <div
                          className='bg-primary text-dark text-center font-weight-medium py-2'
                          style={{ width: '170px' }}
                        >
                          Breaking News
                        </div>
                        <div
                          className='owl-carousel tranding-carousel position-relative d-inline-flex align-items-center ml-3'
                          style={{
                            width: 'calc(100% - 170px)',
                            display: 'inlineBlock',
                            marginRight: '50px',
                            whiteSpace: 'nowrap',
                            textDecoration: 'none'
                          }}
                        >
                          <marquee>
                            {cards.slice(6, 10).map((item, index) => (
                              <a
                                className='text-white text-uppercase font-weight-semi-bold ml-5'
                                onClick={() => {
                                  window.location.href = '/news/' + item.slug
                                }}
                              >
                                {item.title.rendered}
                              </a>
                            ))}
                          </marquee>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
            {!(
              location.pathname.includes('/news/') &&
              location.pathname.split('/news/')[1]
            ) ? (
              <Box sx={{ width: '100%', margin: '0px' }}>
                <div className='container mb-3'>
                  <div className='row'>
                    <div className='col-lg-12 mt-3'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='section-title'>
                            <h4 className='m-0 text-uppercase font-weight-bold'>
                              Top Indian News
                            </h4>
                          </div>
                        </div>
                        <IndianNews />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='row mt-3'>
                        <div className='col-12'>
                          <div className='section-title'>
                            <h4 className='m-0 text-uppercase font-weight-bold'>
                              Top Global News
                            </h4>
                          </div>
                        </div>
                        <GlobalNews />
                      </div>
                      <div className='mb-3 mt-3'>
                        <div className='section-title mb-0'>
                          <h4 className='m-0 text-uppercase font-weight-bold'>
                            Advertisement
                          </h4>
                        </div>
                        <div className=' text-center border border-top-0 pb-3'>
                          <a href=''>
                            <ImageWithLoader
                              className='img-fluid'
                              src={adsData.ad_manager_slot_3}
                              alt=''
                            />
                          </a>
                        </div>
                      </div>
                      {/* Newsletter Start */}
                    </div>
                    <div className='col-lg-12'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='section-title'>
                            <h4 className='m-0 text-uppercase font-weight-bold'>
                              Trending News
                            </h4>
                          </div>
                        </div>
                        {fetchPart2.slice(0, 1).map((item, index) => (
                          <div className='col-lg-12'>
                            <div className='position-relative mb-3'>
                              <ImageWithLoader
                                className='img-fluid w-100 h-300'
                                src={featuredImages2[index]}
                                style={{ objectFit: 'cover', height: '300px' }}
                              />
                              <div
                                className='bg-white border border-top-0 p-4'
                                style={{ height: '350px' }}
                              >
                                <div className='mb-2'>
                                  <a
                                    className='badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2'
                                    href=''
                                  >
                                    {item?.categories?.[0]}
                                  </a>
                                  <a className='text-body' href=''>
                                    <small>
                                      {new Intl.DateTimeFormat('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit'
                                      }).format(new Date(item.date))}
                                    </small>
                                  </a>
                                </div>
                                <a
                                  className='h4 d-block mb-3 text-secondary text-uppercase font-weight-bold'
                                  onClick={() => {
                                    window.location.href = '/news/' + item.slug
                                  }}
                                >
                                  {item.title.rendered}
                                </a>
                                <p
                                  className='m-0 p-2'
                                  style={{ textAlign: 'justify' }}
                                // dangerouslySetInnerHTML={{
                                //   __html:
                                //     item?.content?.rendered.slice(0, 500) +
                                //     `<a href='${'/news/' + item.slug
                                //     }'>... Read More</a>`
                                // }}
                                >

                                  {decodeHtml(item?.content?.rendered.slice(0, 500))}
                                  <a href={'/news/${item.slug}'}>... Read More</a>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className='col-lg-12 mb-3'>
                          <a href=''>
                            <ImageWithLoader
                              className='img-fluid w-100'
                              src={adsData.ad_manager_slot_1}
                              alt=''
                            />
                          </a>
                        </div>
                        <div className='section-title mb-0'>
                          <h4 className='m-0 text-uppercase font-weight-bold'>
                            Market Watch
                          </h4>
                        </div>
                        <Data />
                        <div className='col-lg-12 mb-3'>
                          <div className='row'>
                            <div className='col-12'>
                              <div className='section-title'>
                                <h4 className='m-0 text-uppercase font-weight-bold'>
                                  Weekly News
                                </h4>
                              </div>
                            </div>
                            <WeeklyNews />
                          </div>
                        </div>

                        <div className='col-lg-12 mb-3'>
                          <div className='row'>
                            <div className='col-12'>
                              <div className='section-title'>
                                <h4 className='m-0 text-uppercase font-weight-bold'>
                                  Blogs
                                </h4>
                              </div>
                            </div>
                            <BLogNews showTitle={false} />
                          </div>
                        </div>

                        {/*<div className='col-lg-12 mb-3 mt-3'>
                          <div className='row'>
                            <div className='col-12'>
                              <div className='section-title'>
                                <h4 className='m-0 text-uppercase font-weight-bold'>
                                  Editor Picks
                                </h4>
                              </div>
                            </div>

                            <Box className='container'>

                              {fetchPart2.slice(5, 9).map((card, index) => (
                                <>


                                  {/* <div className='position-relative mb-3'>
                              <ImageWithLoader
                                className='img-fluid w-100 h-300'
                                src={featuredImages2[index + 5]}
                                style={{ objectFit: 'cover', height: '300px' }}
                              />
                              <div
                                className='bg-white border border-top-0 p-4'
                                style={{ height: '350px' }}
                              >
                                <div className='mb-2'>
                                  <a
                                    className='badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2'
                                    href=''
                                  >
                                    {item.categories[0] == 24
                                      ? 'Global'
                                      : 'Indian'}
                                  </a>
                                  <a className='text-body' href=''>
                                    <small>
                                      {new Intl.DateTimeFormat('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit'
                                      }).format(new Date(item.date))}
                                    </small>
                                  </a>
                                </div>
                                <a
                                  className='card-title d-block mb-3'
                                  onClick={() => {
                                    window.location.href = '/news/' + item.slug
                                  }}
                                >
                                  {item.title.rendered}
                                </a>
                                <p
                                  className='m-0 p-2'

                                >
                                  {decodeHtml(item?.content?.rendered.slice(0,200))}
                                </p>
                              </div>
                            </div> 




                                  <Box
                                    key={card.id}
                                    className="card"
                                    onClick={() => {
                                      window.location.href = "/news/" + card.slug;
                                    }}>
                                    <Box
                                      className="card-image"
                                      style={{
                                        backgroundImage: `url(${featuredImages2[index + 5] || "https://via.placeholder.com/300"})`,
                                      }}></Box>
                                    <Box className="card-content">
                                      <Typography className="card-title" sx={{ textAlign: 'justify' }}>
                                        {decodeHtml(card.title?.rendered)}
                                      </Typography>
                                      <Typography className="card-description" sx={{ textAlign: 'justify' }}>
                                        {decodeHtml(card.excerpt?.rendered)}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </>
                              ))}
                            </Box>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='mb-3'>
                        <div className='section-title mb-0'>
                          <h4 className='m-0 text-uppercase font-weight-bold'>
                            Advertisement
                          </h4>
                        </div>
                        <div className=' text-center border border-top-0 pb-3'>
                          <a href=''>
                            <ImageWithLoader
                              className='img-fluid'
                              src={adsData.ad_manager_slot_2}
                              alt=''
                            />
                          </a>
                        </div>
                      </div>
                      {/* Newsletter Start */}
                    </div>
                  </div>
                </div>
              </Box>
            ) : (
              <div className="container-fluid">
                <div className="row g-4">
                  {/* Left Column - Indian News */}
                  <div className="col-lg-3">
                    <div className="row">
                      <div className="col-12">
                        <div className="section-title">
                          <h4 className="m-0 text-uppercase font-weight-bold">
                            Indian News
                          </h4>
                        </div>
                      </div>
                      <IndianNews />
                    </div>
                  </div>

                  {/* Center Column - Main Content */}
                  <div className="col-lg-6 position-relative">
                    <Box sx={{ width: '100%', overflow: 'auto !important', display: 'relative' }}>
                      <SwipeableList
                        sx={{ overflow: 'auto !important' }}
                        threshold={0.01}
                      >
                        <SwipeableListItem
                          leadingActions={leadingActions()}
                          trailingActions={trailingActions()}
                          sx={{}}
                        >
                          <div
                            ref={cardContentRef}
                            className={`card-container ${animationDirection === 'right'
                              ? 'slide-in-right'
                              : animationDirection === 'left'
                                ? 'slide-in-left'
                                : ''
                              }`}
                            onAnimationEnd={handleAnimationEnd}
                          >
                            <Box
                              sx={{
                                background: currentImage
                                  ? `url(${currentImage})`
                                  : 'https://via.placeholder.com/300', // Fallback image
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                height: '300px' // Adjust as needed
                              }}
                              className='current-image'
                            ></Box>

                            <Box
                              sx={{
                                background: '#ffffff',
                                margin: '-30px 0px',
                                borderRadius: '10px',
                                boxShadow: '0px 0px 10px rgba(0,0,0,1)',
                                minHeight: '100vH',
                                textAlign: 'left',
                                overflow: 'auto !important'
                              }}
                            >
                              <Typography
                                variant='h5'
                                component='div'
                                sx={{
                                  padding: '15px',
                                  boxShadow: 'inset 0px 0px 10px rgba(0,0,0,0.1)'
                                }}
                              >
                                {decodedTitle}
                              </Typography>

                              {/* Display formatted date */}
                              <Typography
                                sx={{
                                  mt: 1,
                                  fontSize: 14,
                                  color: 'text.secondary',
                                  padding: '0px 15px'
                                }}
                              >
                                {formattedDate}
                              </Typography>

                              {/* Render HTML content safely using `dangerouslySetInnerHTML` */}
                              <Typography
                                sx={{ padding: '0px 15px', marginBottom: '150px' }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: currentCard?.content?.rendered
                                      ? processShortcodes(currentCard.content.rendered)
                                      : "Loading..."
                                  }}
                                />
                              </Typography>
                            </Box>
                          </div>
                        </SwipeableListItem>
                      </SwipeableList>

                      {/* Bottom Action Bar */}
                      <Box
                        sx={{
                          background: '#000000',
                          // margin: '15px',
                          padding: '15px',
                          boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
                          borderRadius: '38px',
                          position: 'fixed',
                          width: 'fit-content', // Changed from 100% to fit-content
                          zIndex: 100,
                          bottom: '64px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          left: '50%', // Added to center horizontally
                          transform: 'translateX(-50%)', // Added to center horizontally
                        }}
                      >
                        {loginStatus.token ? (
                          <>
                            {/* Like Button *
                            <IconButton
                              aria-label='like'
                              onClick={() => setIsLiked(!isLiked)}
                              sx={{ color: isLiked ? '#1976d2' : '#757575' }}
                            >
                              {isLiked ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />}
                              <Typography
                                variant='caption'
                                sx={{ marginLeft: '4px' }}
                              >
                                {isLiked ? 'Liked' : 'Like'}
                              </Typography>
                            </IconButton>

                            {/* Comment Button *
                            <IconButton
                              aria-label='comment'
                              onClick={toggleDrawer(true, currentCard?.id)}
                              sx={{ color: '#4caf50' }}
                            >
                              <CommentIcon />
                              <Typography
                                variant='caption'
                                sx={{ marginLeft: '4px' }}
                              >
                                Comment
                              </Typography>
                            </IconButton>

                            {/* Share Button */}
                            <IconButton
                              aria-label='share'
                              onClick={handleShare}
                              sx={{ color: '#f50057' }}
                            >
                              <ShareIcon />
                              <Typography
                                variant='caption'
                                sx={{ marginLeft: '4px' }}
                              >
                                Share
                              </Typography>
                            </IconButton>

                            <Drawer
                              anchor='bottom'
                              open={isDrawerOpen}
                              onClose={toggleDrawer(false, currentCard?.id)}
                            >
                              <Box
                                sx={{
                                  padding: '16px',
                                  height: '50vh',
                                  overflow: 'auto',
                                  backgroundColor: '#333' // Dark background for contrast
                                }}
                              >
                                <Typography
                                  variant='h6'
                                  sx={{ marginBottom: '16px', color: 'white' }}
                                >
                                  Comments
                                </Typography>
                                {loadingComments ? (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      height: '100%'
                                    }}
                                  >
                                    <CircularProgress color='inherit' />
                                  </Box>
                                ) : comments.length > 0 ? (
                                  <List>
                                    {comments.map(comment => (
                                      <ListItem
                                        key={comment.id}
                                        alignItems='flex-start'
                                      >
                                        <ListItemText
                                          primary={comment.author_name}
                                          secondary={comment.content.rendered}
                                          primaryTypographyProps={{
                                            style: { color: 'white' }
                                          }}
                                          secondaryTypographyProps={{
                                            style: { color: 'white' }
                                          }}
                                        />
                                      </ListItem>
                                    ))}
                                  </List>
                                ) : (
                                  <Typography sx={{ color: 'white' }}>
                                    No comments available.
                                  </Typography>
                                )}
                                {/* Add Comment Section */}
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: '16px',
                                    alignItems: 'center' // Center content horizontally
                                  }}
                                >
                                  <TextField
                                    variant='outlined'
                                    fullWidth
                                    placeholder='Write a comment...'
                                    value={newComment}
                                    onChange={e => setNewComment(e.target.value)}
                                    sx={{
                                      backgroundColor: 'white',
                                      borderRadius: '8px',
                                      marginBottom: '8px',
                                      width: '90%' // Slightly narrower than full width
                                    }}
                                  />
                                  <Button
                                    variant='contained'
                                    color='primary'
                                    disabled={commentSubmitting}
                                    sx={{
                                      width: '200px' // Fixed width for button
                                    }}
                                  >
                                    {commentSubmitting
                                      ? 'Submitting...'
                                      : 'Add Comment'}
                                  </Button>
                                </Box>
                              </Box>
                            </Drawer>
                          </>
                        ) : (
                          <Link
                            to='/profile'
                            style={{
                              textDecoration: 'none',
                              color: '#000000',
                              fontWeight: 700,
                              display: 'flex',
                              width: "100%",
                              justifyContent: 'center',
                              marginInline: 'auto',
                            }}
                          >
                            Login to Share News and get latest news on Email
                          </Link>
                        )}
                      </Box>
                    </Box>


                    <div className='col-lg-12 mb-3 mt-3'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='section-title'>
                            <h4 className='m-0 text-uppercase font-weight-bold'>
                              Blogs
                            </h4>
                          </div>
                        </div>
                        <BLogNews showTitle={false} />
                      </div>
                    </div>
                  </div>

                  {/* Right Column - Global News */}
                  <div className="col-lg-3">
                    <div className="row">
                      <div className="col-12">
                        <div className="section-title">
                          <h4 className="m-0 text-uppercase font-weight-bold">
                            Global News
                          </h4>
                        </div>
                      </div>
                      <GlobalNews />
                    </div>
                  </div>
                </div>
              </div>

            )}
          </>
        </>
      )}
    </>
  )
}

export default Initial
