import React, { useState, useEffect, useRef } from 'react'
import './GlobalNewsDetail.scss' // Define animation styles here
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  Button
} from '@mui/material'
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions
} from 'react-swipeable-list'
import 'react-swipeable-list/dist/styles.css'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import CommentIcon from '@mui/icons-material/Comment'
import ShareIcon from '@mui/icons-material/Share'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ImageWithLoader from '../ImageWithLoader/ImageWithLoader'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useAuth } from '../../services/authContext'
import { Link } from 'react-router-dom'
import { format } from 'date-fns' // Import date-fns for formatting dates
import {
  FetchNewsApi,
  FetchMediaApi,
  FetchPostsBySlugApi,
  FetchPostsByCategoryApi
} from '../../services/apis'
import NewsCard from '../Dairynews7x7/NewsCard'
let j = 0
let i = 0
const GlobalNewsDetail = () => {
  const [cards, setCards] = useState([])
  const [featuredImages, setFeaturedImages] = useState([]) // New state for storing image URLs
  const [currentCardIndex, setCurrentCardIndex] = useState(0)
  const [animationDirection, setAnimationDirection] = useState('')
  const cardContentRef = useRef(null)
  const [isLiked, setIsLiked] = useState(false)
  const { loginStatus, setLoginStatus } = useAuth()
  const [error, setError] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('indian-news')

  // Utility function to decode HTML entities
  const decodeHtml = html => {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.documentElement.textContent
  }

  const fetchData = async formattedDate => {
    try {
      let page = 1
      const postsPerPage = 69
      const response = await FetchPostsByCategoryApi({
        per_page: postsPerPage,
        before: formattedDate,
        category_id: 24,
        page
      })

      const data = await response
      setCards(data) // Store posts in state

      // Fetch featured images for each card
      const imagePromises = data.map(async post => {
        if (post.featured_media) {
          try {
            const mediaResponse = await FetchMediaApi({
              featured_media_id: post.featured_media
            })
            return mediaResponse?.source_url || null // Use `source_url` or fallback to `null`
          } catch (error) {
            console.error(`Error fetching media for post ${post.id}:`, error)
            return null
          }
        }
        return Promise.resolve(null) // If no featured_media, return null
      })

      const images = await Promise.all(imagePromises)
      setFeaturedImages(images) // Store images in state
    } catch (error) {
      setError('Error fetching posts.')
      console.error(error)
    }
  }

  // Fetch data from API and handle login status
  useEffect(() => {
    // Fetch posts
    const today = new Date()
    const hundredDaysAgo = new Date(today)
    hundredDaysAgo.setDate(today.getDate()) // Calculate 100 days ago
    const formattedDate = hundredDaysAgo.toISOString()
    fetchData(formattedDate)

    // Handle login status
    const loginStat = localStorage.getItem('loginCred')
    if (loginStat) {
      const parsedLoginStat = JSON.parse(loginStat)
      setLoginStatus(parsedLoginStat)
    } else {
      setLoginStatus([])
    }
  }, [setLoginStatus])

  // Handle swipe left (previous card)
  const handleSwipeLeft = () => {
    setAnimationDirection('left')
    setCurrentCardIndex(prevIndex =>
      prevIndex === 0 ? cards.length - 1 : prevIndex - 1
    )
    if (cardContentRef.current) {
      cardContentRef.current.scrollTop = 0
    }
  }

  const handleNextPage = () => { }

  const handlePreviousPage = () => { }

  // Handle swipe right (next card)
  const handleSwipeRight = () => {
    setAnimationDirection('right')
    setCurrentCardIndex(prevIndex => (prevIndex + 1) % cards.length)
    if (cardContentRef.current) {
      cardContentRef.current.scrollTop = 0
    }

    j = j + 1
    console.log(j)
    console.log(i)
    if (j === 5) {
      i = i - 9
      const today = new Date()
      const hundredDaysAgo = new Date(today)
      hundredDaysAgo.setDate(today.getDate() + i) // Calculate 100 days ago
      const formattedDate = hundredDaysAgo.toISOString()
      fetchData(formattedDate)
      j = 0
    }
  }

  // Reset animation state after the animation ends
  const handleAnimationEnd = () => {
    setAnimationDirection('')
  }

  const baseUrl = window.location.origin // Dynamically gets the base URL

const handleShare = async () => {
    const currentCard = cards[currentCardIndex]
    const currentImage = featuredImages[currentCardIndex]
    const shareUrl = `${baseUrl}/share/${currentCard?.slug}`
    const shareData = {
      title: currentCard?.title?.rendered || 'Check this out!',
      text: currentCard?.excerpt?.rendered.replace(/<[^>]*>/g, '') || 'Check out this amazing article!',
      url: shareUrl
    }
    

    if (navigator.canShare && currentImage) {
      try {
        const response = await fetch(currentImage); // Fetch the image from the URL
        const blob = await response.blob(); // Convert response to Blob
        const file = new File([blob], "shared-image.jpg", { type: blob.type }); // Create a File object
  
        if (navigator.canShare({ files: [file] })) {
          shareData.files = [file]; // Add the image file to the shareData
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    }

    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log('Shared successfully:', shareUrl))
        .catch(error => console.error('Error sharing:', error))
    } else {
      // Fallback for unsupported browsers
      alert(
        `Sharing is not supported in your browser. Copy this link: ${shareUrl}`
      )
    }
  }

  // Decode the title before rendering
  const currentCard = cards[currentCardIndex]
  const currentImage = featuredImages[currentCardIndex] // Get the featured image for the current card
  const decodedTitle = currentCard?.title?.rendered
    ? decodeHtml(currentCard.title.rendered)
    : ''

  // Format the date using date-fns
  const formattedDate = currentCard?.date
    ? format(new Date(currentCard.date), 'MMMM dd, yyyy')
    : ''

  // Define swipeable actions
  const leadingActions = () => (
    <LeadingActions>
      <SwipeAction onClick={handleSwipeLeft}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <ArrowBackIcon sx={{ fontSize: '48px', color: '#999' }} />
        </Box>
      </SwipeAction>
    </LeadingActions>
  )

  const trailingActions = () => (
    <TrailingActions>
      <SwipeAction onClick={handleSwipeRight}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <ArrowForwardIcon sx={{ fontSize: '48px', color: '#999' }} />
        </Box>
      </SwipeAction>
    </TrailingActions>
  )

  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <>
      {isMobile ? (
        <Box sx={{ width: '100%', overflow: 'auto !important' }}>
          <SwipeableList sx={{ overflow: 'auto !important' }} threshold={0.01}>
            <SwipeableListItem
              leadingActions={leadingActions()}
              trailingActions={trailingActions()}
              sx={{ overflow: 'auto !important' }}
            >
              <div
                ref={cardContentRef}
                className={`card-container ${animationDirection === 'right'
                  ? 'slide-in-right'
                  : animationDirection === 'left'
                    ? 'slide-in-left'
                    : ''
                  }`}
                onAnimationEnd={handleAnimationEnd}
              >
                <Box
                  sx={{
                    background: currentImage
                      ? `url(${currentImage})`
                      : 'https://via.placeholder.com/300', // Fallback image
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '300px' // Adjust as needed
                  }}
                  className='current-image'
                ></Box>

                <Box
                  sx={{
                    background: '#ffffff',
                    margin: '-30px 0px',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0,0,0,1)',
                    minHeight: '100vH',
                    textAlign: 'left',
                    overflow: 'auto !important'
                  }}
                >
                  <Typography
                    variant='h5'
                    component='div'
                    sx={{
                      padding: '15px',
                      boxShadow: 'inset 0px 0px 10px rgba(0,0,0,0.1)'
                    }}
                  >
                    {decodedTitle}
                  </Typography>

                  {/* Display formatted date */}
                  <Typography
                    sx={{
                      mt: 1,
                      fontSize: 14,
                      color: 'text.secondary',
                      padding: '0px 15px'
                    }}
                  >
                    {formattedDate}
                  </Typography>

                  {/* Render HTML content safely using `dangerouslySetInnerHTML` */}
                  <Typography
                    sx={{ padding: '0px 15px', marginBottom: '150px' }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: currentCard?.content?.rendered
                      }}
                    />
                  </Typography>
                </Box>
              </div>
            </SwipeableListItem>
          </SwipeableList>
          {/* Bottom Action Bar */}
          <Box
            sx={{
              background: '#000000',
              margin: '15px',
              padding: '15px',
              boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
              borderRadius: '38px',
              position: 'fixed',
              width: '91%',
              zIndex: 100,
              bottom: '64px',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            {loginStatus.token ? (
              <>
                {/* Like Button *
                <IconButton
                  aria-label='like'
                  onClick={() => setIsLiked(!isLiked)}
                  sx={{ color: isLiked ? '#1976d2' : '#757575' }}
                >
                  {isLiked ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />}
                  <Typography variant='caption' sx={{ marginLeft: '4px' }}>
                    {isLiked ? 'Liked' : 'Like'}
                  </Typography>
                </IconButton>

                {/* Comment Button *
                <IconButton aria-label='comment' sx={{ color: '#4caf50' }}>
                  <CommentIcon />
                  <Typography variant='caption' sx={{ marginLeft: '4px' }}>
                    Comment
                  </Typography>
                </IconButton>

                {/* Share Button */}
                <IconButton
                  aria-label='share'
                  onClick={handleShare}
                  sx={{ color: '#f50057' }}
                >
                  <ShareIcon />
                  <Typography variant='caption' sx={{ marginLeft: '4px' }}>
                    Share
                  </Typography>
                </IconButton>
              </>
            ) : (
              <Link
                to='/profile'
                style={{
                  textDecoration: 'none',
                  color: '#000000',
                  fontWeight: 700
                }}
              >
                Login to Share News and get latest news
              </Link>
            )}
          </Box>
        </Box>
      ) : (
        <>


          <Box
            sx={{
              marginTop: "200px",
              marginX: "auto"
            }}
            className='container'
          >
            <div className='section-title'>
              <h4 className='m-0 text-uppercase font-weight-bold'>
                Global News
              </h4>
            </div>
          </Box>

          <Box className='container'>
            {cards.map((card, index) => (
              <NewsCard card={card} key={index} featuredImage={featuredImages[index] || 'https://via.placeholder.com/300'} />
            ))}
          </Box>
        </>
      )
      }

    </>
  )
}

export default GlobalNewsDetail
