import { BEBase } from '../constants/app.constant'

export const LoginApi = request => {
  const url = BEBase + 'profile/'

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Invalid Login Credentials')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('Invalid Login Credentials', error)
      throw error // Rethrow the error for further handling
    })
}

export const RegistrationApi = (request) => {
  const url = BEBase + 'Register/'  // Assuming this is your registration endpoint

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Registration Failed')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('Registration Failed', error)
      throw error // Rethrow the error for further handling
    })
}

export const ForgetPassscodeApi = request => {
  const url = BEBase + 'forget_password/'

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Invalid Login Credentials')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('Invalid Login Credentials', error)
      throw error // Rethrow the error for further handling
    })
}

export const VerifyOtpApi = request => {
  const url = BEBase + 'verify_otp/'

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Invalid Login Credentials')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('Invalid Login Credentials', error)
      throw error // Rethrow the error for further handling
    })
}

export const RegisterApi = request => {
  const url = BEBase + 'signup/'

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListUsersApi = request => {
  const url = BEBase + 'list_users/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListTeachersApi = request => {
  const url = BEBase + 'list_teacher/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: request
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateTaskApi = request => {
  const url = BEBase + 'create_paid_task/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify({
      username: request.username,
      amount: request.amount,
      taskHeading: request.taskHeading,
      taskDescription: request.taskDescription,
      paymentLink: request.paymentLink
    })
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListTaskApi = request => {
  const url = BEBase + 'list_user_task/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateCourseApi = formData => {
  const url = BEBase + 'create_product/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token,
      'Content-Type': 'application/json'
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('Section Not Added or Some Other error', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListProductApi = request => {
  const url = BEBase + 'list_product/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify({ adminToken: JSON.parse(authToken).token })
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateCourseApi = formData => {
  const url = BEBase + 'update_product/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token,
      'Content-Type': 'application/json'
      // Removed Content-Type; let the browser handle it automatically
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteCourseApi = formData => {
  const url = BEBase + 'delete_product/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteSectionApi = formData => {
  const url = BEBase + 'delete_section/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateCourseImageApi = formData => {
  const url = BEBase + 'update_product_image/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
      // Removed Content-Type; let the browser handle it automatically
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateSubjectApi = formData => {
  const url = BEBase + 'create_subject/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListSubjectApi = request => {
  const url = BEBase + 'list_subject/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify({ adminToken: JSON.parse(authToken).token })
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteSubjectApi = formData => {
  const url = BEBase + 'delete_subject/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateSubjectApi = formData => {
  const url = `${BEBase}update_subject/?_=${new Date().getTime()}` // Append a timestamp
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
      // Removed Content-Type; let the browser handle it automatically
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateFeesApi = formData => {
  const url = BEBase + 'create_fees/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateTeacherApi = formData => {
  const url = BEBase + 'create_teacher/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(formData) // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListFeesApi = request => {
  const url = BEBase + 'list_fees/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify({ adminToken: JSON.parse(authToken).token })
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteFeesApi = formData => {
  const url = BEBase + 'delete_fees/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateFeesApi = formData => {
  const url = `${BEBase}update_fees/?_=${new Date().getTime()}` // Append a timestamp
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token,
      'Content-Type': 'application/json'
      // Removed Content-Type; let the browser handle it automatically
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateTransportApi = formData => {
  const url = BEBase + 'create_transport/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListTransportApi = request => {
  const url = BEBase + 'list_transport/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteTransportApi = formData => {
  const url = BEBase + 'delete_transport/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateTransportApi = formData => {
  const url = `${BEBase}update_transport/?_=${new Date().getTime()}` // Append a timestamp
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
      // Removed Content-Type; let the browser handle it automatically
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateTeacherApi = formData => {
  const url = BEBase + 'update_teacher/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token,
      'Content-Type': 'application/json'
      // Removed Content-Type; let the browser handle it automatically
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteTeacherApi = formData => {
  const url = BEBase + 'delete_teacher/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListTimeTableApi = request => {
  const url = BEBase + 'list_timeTable/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListClassRelatedToSectionApi = request => {
  const url = BEBase + 'class_related_to_section/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListSubjectFromClassNameApi = request => {
  const url = BEBase + 'list_subject_from_className/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateTimeTableApi = formData => {
  const url = BEBase + 'update_timeTable/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token,
      'Content-Type': 'application/json'
      // Removed Content-Type; let the browser handle it automatically
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const TeacherAttendanceApi = formData => {
  const url = BEBase + 'teacher_attendance/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListTeacherAttendanceApi = request => {
  const url = BEBase + 'list_teacher_attendance/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const StudentAttendanceApi = formData => {
  const url = BEBase + 'student_attendance/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListStudentAttendanceApi = request => {
  const url = BEBase + 'list_student_attendance/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const UpdateStudentApi = formData => {
  const url = BEBase + 'update_student/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token,
      'Content-Type': 'application/json'
      // Removed Content-Type; let the browser handle it automatically
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          console.log('Error details:', err)
          throw new Error('Network response was not ok')
        })
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteStudentApi = formData => {
  const url = BEBase + 'delete_student/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateStudentApi = formData => {
  const url = BEBase + 'create_student/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(formData) // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListStudentsApi = request => {
  const url = BEBase + 'list_student/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateStudentHomeworkApi = formData => {
  const url = BEBase + 'create_student_homework/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListStudentHomeworkApi = request => {
  const url = BEBase + 'list_student_homework/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListTeacherTimetableApi = request => {
  const url = BEBase + 'list_teacher_timeTable/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const ListSubjectFromIdApi = request => {
  const url = BEBase + 'list_subject_from_id/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const DeleteStudentHomeworkApi = formData => {
  const url = BEBase + 'delete_student_homework/'
  const authToken = localStorage.getItem('loginCred')

  console.log('Request payload:', formData)

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: formData // Directly use the FormData object
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      window.location.reload()
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const FetchNewsApi = request => {
  const url = BEBase + 'fetch_news/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const FetchMediaApi = request => {
  const url = BEBase + 'fetch_media/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const FetchPostsBySlugApi = request => {
  const url = BEBase + 'fetch_posts_by_slug/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const FetchPostsByCategoryApi = request => {
  const url = BEBase + 'fetch_posts_by_category/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const FetchPostsBySearchApi = request => {
  const url = BEBase + 'fetch_posts_by_search/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const WorkSheetApi = () => {
  const url = BEBase + 'Worksheet/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const CreateCommentApi = request => {
  const url = 'https://back.dairynews7x7.com/wp-json/wp/v2/comments'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + JSON.parse(authToken).token
    },
    body: JSON.stringify({
      post: request.postId,
      content: request.newComment,
      author_name: 'Anonymous', // Replace with actual author name if logged in
      author_email: 'anonymous@example.com' // Replace with actual author email if logged in
    })
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const FetchChannelVideosApi = () => {
  const url = BEBase + 'fetch_channel_videos/'
  const authToken = localStorage.getItem('loginCred')
  return fetch(url, {
    method: 'POST'
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error)
      throw error // Rethrow the error for further handling
    })
}

export const SubscribeApi = (email) => {
  const url = BEBase + 'subscribe/'; // Updated to the subscription endpoint
  console.log(url);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', // Ensure JSON format for the request
    },
    body: JSON.stringify({ email }), // Include the email in the request body
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Return the parsed JSON response
    })
    .catch(error => {
      console.log('There was a problem with the fetch operation:', error);
      throw error; // Rethrow the error for further handling
    });
};

export const FetchAdsApi = () => {
  const url = BEBase + 'fetch_ads/'; // URL for the fetch ads endpoint
  return fetch(url, {
    method: 'POST', // Using POST method to fetch data
    headers: {
      'Content-Type': 'application/json', // JSON format for the response
    },
    body: JSON.stringify({}), // Sending an empty body for POST request
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Return the parsed JSON response
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
      throw error; // Rethrow the error for further handling
    });
};

export const GetCustomUsersApi = () => {
  const url = BEBase + 'fetch_registered_users/'

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch custom users')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('Fetch operation failed:', error)
      throw error // Rethrow the error for further handling
    })
}

export const GetSubscribersApi = () => {
  const url = BEBase + 'fetch_all_subscribers/'

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch subscribers')
      }
      return response.json() // Return the parsed JSON response
    })
    .catch(error => {
      console.log('Fetch operation failed:', error)
      throw error // Rethrow the error for further handling
    })
}
