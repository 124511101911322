import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Layout from "./components/Layout/Layout";
import Admin from './components/Admin/Admin';
import Settings from './components/Settings/Settings';
import Terms from './components/Terms/Terms';
import Privacy from './components/Privacy/Privacy';
import Search from './components/Search/Search';
import DataDetail from './components/DataDetail/DataDetail';
import Profile from './components/Profile/Profile';
import Register from './components/Profile/Register';
import IndianNewsDetail from './components/IndianNewsDetail/IndianNewsDetail';
import WeeklyNews from './components/WeeklyNews/WeeklyNews';
import BLogNews from './components/BlogNews/BlogNews';
import GlobalNewsDetail from './components/GlobalNewsDetail/GlobalNewsDetail';
import Contact from './components/Dairynews7x7/Contact';
import AboutPage from './components/Dairynews7x7/About';
import usePageTracking from './services/usePageTracking';


function App() {
  usePageTracking()
  return (

    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path='/add_user' element={<Admin />} />
        <Route path='/data' element={<DataDetail />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/search' element={<Search />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/indian' element={<IndianNewsDetail />} />
        <Route path='/global' element={<GlobalNewsDetail />} />
        <Route path='/weekly' element={<WeeklyNews />} />
        <Route path='/blogs' element={<BLogNews />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path="/register" element={<Register />} />
      </Route>
    </Routes>

  );
}

export default App;

