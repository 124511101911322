import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

const DEFAULT_IMAGE = "https://dairynews7x7.com/img_frontend/logonews.png";

const NewsCard = ({ card, featuredImage }) => {
  const [imageSrc, setImageSrc] = useState(featuredImage || DEFAULT_IMAGE);

  useEffect(() => {
    if (!featuredImage) return;

    const img = new Image();
    img.src = featuredImage;

    img.onload = () => {
      setImageSrc(featuredImage);
    };

    img.onerror = () => {
      setImageSrc(DEFAULT_IMAGE);
    };
  }, [featuredImage]);

  const decodeHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.documentElement.textContent;
  };

  return (
    <Box
      key={card.id}
      className="card"
      onClick={() => {
        window.location.href = "/news/" + card.slug;
      }}
    >
      {/* Image with onError handling */}
      <img
        src={imageSrc}
        alt={decodeHtml(card.title?.rendered)}
        style={{
          width: "100%",
          height: "200px",
          objectFit: "cover",
        }}
      />

        <Box className="card-content">
            <Typography className="card-title" sx={{ textAlign: 'justify', fontWeight: 'bold' }}>
                {decodeHtml(card.title?.rendered)}
            </Typography>
            <Typography className="card-description" sx={{ textAlign: "justify" }}>
                {decodeHtml(card.content?.rendered).length > 200 ? (
                    <>
                        {decodeHtml(card.content?.rendered).slice(0, 200).trim()}...
                        <span style={{ fontWeight: "bold", textDecoration: "underline", cursor: "pointer", color: "#e48c48" }}>
                            Read More
                        </span>
                    </>
                ) : (
                    decodeHtml(card.content?.rendered)
                )}
            </Typography>


        </Box>
    </Box>
  );
};

export default NewsCard;
