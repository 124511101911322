import React, { useState, useEffect } from 'react';
import './Search.scss';
import { useNavigate } from 'react-router-dom';
import { Button, useMediaQuery } from '@mui/material';
import {
  FetchPostsBySearchApi
} from '../../services/apis';

const Search = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [filteredNews, setFilteredNews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1); // Track current page
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 600px)')
  // Update the debounced query after 500ms of inactivity
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler); // Cleanup timeout if the component re-renders
    };
  }, [searchQuery]);

  // Fetch news based on search query and page
  useEffect(() => {
    if (debouncedQuery.trim() === '') {
      setFilteredNews([]);
      return;
    }

    const fetchNews = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await FetchPostsBySearchApi({
          search_query: debouncedQuery,
          per_page: 100,
          page
        })
        if (!response) {
          throw new Error('Failed to fetch news');
        }

        const data = await response;

        // Check if data is an array before filtering
        if (Array.isArray(data)) {
          // Filter posts by title
          const filteredByTitle = data.filter((post) =>
            post.title?.rendered?.toLowerCase().includes(debouncedQuery)
          );
          setFilteredNews(filteredByTitle);
        } else {
          setFilteredNews([]);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNews();
  }, [debouncedQuery, page]);

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage((prevPage) => prevPage - 1);
  };

  return (
    <div
      className="Search"
      data-testid="Search"
      style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}
    >
      {!isMobile && (

        <div style={{ marginTop: "150px" }}>
        </div>
      )}
      <input
        type="text"
        placeholder="Search by title..."
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value.toLowerCase())}
        style={{
          width: '100%',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          marginBottom: '20px',
          marginTop: '80px',
        }}
      />
      {isLoading ? (
        <p style={{ textAlign: 'center', color: '#999' }}>Loading...</p>
      ) : error ? (
        <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>
      ) : filteredNews.length > 0 ? (
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {filteredNews.map((news) => (
            <li
              key={news.id}
              style={{
                marginBottom: '15px',
                padding: '10px',
                border: '1px solid #ddd',
                borderRadius: '4px',
                backgroundColor: '#f9f9f9',
              }}
            >
              <h3 style={{ margin: 0, marginBottom: '10px' }}>
                {news.title?.rendered}
              </h3>
              <p
                style={{ margin: 0 }}
                dangerouslySetInnerHTML={{
                  __html: news.excerpt?.rendered || 'No description available',
                }}
              ></p>
              <Button
                variant="contained"
                onClick={() => navigate(`/news/${news.slug}`)}
                sx={{ background: '#000000' }}
              >
                See Full
              </Button>
            </li>
          ))}
        </ul>
      ) : searchQuery ? (
        <p style={{ textAlign: 'center', color: '#999' }}>
          No news found with the title "{searchQuery}".
        </p>
      ) : null}
      <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: "100px" }}>
        <Button
          variant="outlined"
          onClick={handlePreviousPage}
          disabled={page === 1}
          style={{ marginRight: '10px' }}
        >
          Previous
        </Button>
        <span>Page {page}</span>
        <Button
          variant="outlined"
          onClick={handleNextPage}
          style={{ marginLeft: '10px' }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Search;
