// export const BEBase = 'https://dairynews7x7.com/api';
export const BEBase = 'https://dairynews7x7.com/api';

export const PaymentStatus = {
  SUCCESS: 'Success',
  FAILURE: 'Failure',
  PENDING: 'Pending'
};

export const client_id =
  '582324629736-s17vpu6cmrt2fum1l7njj8ki0khh1j9t.apps.googleusercontent.com';

export const project_id = 'tutorlix-413715';

export const auth_uri = 'https://accounts.google.com/o/oauth2/auth';

export const token_uri = 'https://oauth2.googleapis.com/token';

export const auth_provider_x509_cert_url =
  'https://www.googleapis.com/oauth2/v1/certs';

export const client_secret = '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';

export const wp_link = 'http://localhost:3000/api';
