import React, { useState } from 'react'
import './Header.scss'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import { useAuth } from '../../services/authContext'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import PropTypes from 'prop-types'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Initial from '../Initial/Initial'
import ImageWithLoader from '../ImageWithLoader/ImageWithLoader'
import { Outlet, Link, useLocation } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Button,
  TextField,
  Chip,
  Modal,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery
} from '@mui/material'
import {
  Home as HomeIcon,
  Search as SearchIcon,
  Timeline as TimelineIcon,
  AccountCircle as ProfileIcon,
  Settings as SettingsIcon
} from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import Avatar from '@mui/material/Avatar'
import CloseIcon from '@mui/icons-material/Close'
import SignupForm from '../SignupForm/SignupForm' // Assuming SignupForm is in the same directory
import LoginForm from '../LoginForm/LoginForm'
import GoogleTranslate from '../GoogleTranslate/GoogleTranslate'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 6,
  color: '#000000'
}

const drawerWidth = 240
const navItems = [
  { text: 'About', to: '/about' },
  { text: 'Contact', to: '/contact' }
]

const navItemsLogout = [
  { text: 'About', to: '/about' },
  { text: 'Contact', to: '/contact' }
]

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#000000' },
    warning: { main: '#f5c44e' }
    // Add more theme customizations as needed
  }
})

const Header = props => {
  const [value, setValue] = React.useState(0)
  const { loginStatus } = useAuth()
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [showSignupForm, setShowSignupForm] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [otp, setOtp] = React.useState('')
  const [step, setStep] = React.useState(1) // Step 1: Phone Input, Step 2: OTP Input
  const [selectedCategory, setSelectedCategory] = useState('')
  const location = useLocation()

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState)
  }

  const container =
    window !== undefined ? () => window().document.body : undefined
  const handleOpen = () => {
    setShowSignupForm(true)
  }
  const handleClose = () => {
    setShowSignupForm(false)
    setStep(1) // Reset to phone input step
  }

  const handleNavClick = item => {
    if (item === 'Sign Up') {
      handleOpen() // Show the SignupForm when Sign Up is clicked
    } else if (item === 'Login') {
      // Add your login functionality or navigation here if needed
      alert('Login clicked')
    }
  }

  const isMobile = useMediaQuery('(max-width: 1100px)')
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: 'center', width: '100%' }}
    >
      <Typography variant='h6' sx={{ my: 2 }}>
        <Link to='/'>
          <img
            src='/img_frontend/logonews.png'
            alt='Logo'
            className='topLogo'
            style={{
              cursor: 'pointer'
            }}
          />
          
        </Link>
      </Typography>
      <Divider />
      <List sx={{ width: '100%' }}>
        {!loginStatus.token ? (
          <>
            {navItems.map(item => (
              <ListItem
                key={item.text}
                disablePadding
                onClick={() => handleNavClick(item)}
              >
                <ListItemButton sx={{ textAlign: 'center', width: '100%' }}>
                  <Link
                    to={item.to}
                    style={{
                      textDecorationColor: '#000000',
                      textDecoration: 'none',
                      width: '100%'
                    }}
                  >
                    <ListItemText primary={item.text} />
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
          </>
        ) : (
          <>
            {navItemsLogout.map(item => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton sx={{ textAlign: 'center', width: '100%' }}>
                  <Link
                    to={item.to}
                    style={{
                      color: '#000000',
                      textDecoration: 'none',
                      width: '100%'
                    }}
                  >
                    <ListItemText primary={item.text} />
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
          </>
        )}
      </List>
    </Box>
  )

  return (
    <>
      <ThemeProvider theme={theme}>
        {!loginStatus.token ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CssBaseline />
            <AppBar component='nav' sx={{ background: '#000000' }}>
              {/* Top Row */}
              <div style={{width: "100%", background: "#e48c48", padding: "0px 15px"}}>
                <GoogleTranslate />
              </div>
              <Toolbar>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  edge='start'
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: 'none' } }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant='h6'
                  component='div'
                  sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}
                >
                  <Link to='/'>
                    <img
                      src='/img_frontend/logonews.png'
                      alt='Logo'
                      className='topLogo'
                      style={{
                        width: '50px',
                        height: '50px',
                        objectFit: 'cover',
                        cursor: 'pointer'
                      }}
                    />
                  </Link>
                </Typography>

                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {navItems.map(item => (
                    <Button
                      variant={
                        location.pathname === `${item.to}`
                          ? 'contained'
                          : 'outlined'
                      }
                      sx={{
                        background:
                          location.pathname.toLowerCase() === `${item.to}`
                            ? '#212020 !important'
                            : '#010101 !important',
                        borderRadius: '30px',
                        marginRight: '10px'
                      }}
                      key={item.text}
                      onClick={() => setSelectedCategory(item.to)}
                    >
                      <Link
                        to={item.to}
                        style={{ color: '#ffffff', textDecoration: 'none' }}
                      >
                        {item.text}
                      </Link>
                    </Button>
                  ))}
                </Box>

                <Button
                  variant={
                    location.pathname === '/indian' ? 'contained' : 'outlined'
                  }
                  sx={{
                    background:
                      location.pathname.toLowerCase() === '/indian'
                        ? '#212020 !important'
                        : '#010101 !important',
                    borderRadius: '30px',
                    marginRight: '10px'
                  }}
                  onClick={() => setSelectedCategory('indian-news')}
                >
                  <Link
                    to='/indian'
                    style={{ color: '#ffffff', textDecoration: 'none' }}
                  >
                    Indian News
                  </Link>
                </Button>
                <Button
                  sx={{
                    background:
                      location.pathname.toLowerCase() === '/global'
                        ? '#212020 !important'
                        : '#010101 !important',
                    borderRadius: '30px'
                  }}
                  variant={
                    selectedCategory === 'global-news'
                      ? 'contained'
                      : 'outlined'
                  }
                  onClick={() => setSelectedCategory('global-news')}
                >
                  <Link
                    to='/global'
                    style={{ color: '#ffffff', textDecoration: 'none' }}
                  >
                    Global News
                  </Link>
                </Button>
                <Button
                  sx={{
                    background:
                      location.pathname.toLowerCase() === '/blogs'
                        ? '#212020 !important'
                        : '#010101 !important',
                    borderRadius: '30px'
                  }}
                  variant={
                    selectedCategory === 'blog-news' ? 'contained' : 'outlined'
                  }
                  onClick={() => setSelectedCategory('blog-news')}
                >
                  <Link
                    to='/blogs'
                    style={{ color: '#ffffff', textDecoration: 'none' }}
                  >
                    Blogs
                  </Link>
                </Button>
              </Toolbar>
              {/* Bottom Row */}

              {!isMobile && (
                <Box
                  sx={{
                    background: '#202020',
                    display: { xs: 'none', sm: 'flex' }, // Hide on mobile (xs), show on sm and up
                    justifyContent: 'space-around',
                    paddingY: 1
                  }}
                >
                  <Button
                    component={Link}
                    to='/'
                    sx={{
                      color: '#ffffff',
                      textTransform: 'none',
                      flexDirection: 'column'
                    }}
                  >
                    <HomeIcon sx={{ color: '#fff' }} />
                    <Typography variant='caption' sx={{ color: '#fff' }}>
                      Home
                    </Typography>
                  </Button>
                  <Button
                    component={Link}
                    to='/settings'
                    sx={{
                      color: '#ffffff',
                      textTransform: 'none',
                      flexDirection: 'column'
                    }}
                  >
                    <SettingsIcon sx={{ color: '#fff' }} />
                    <Typography variant='caption' sx={{ color: '#fff' }}>
                      Settings
                    </Typography>
                  </Button>
                  <Button
                    component={Link}
                    to='/search'
                    sx={{
                      color: '#ffffff',
                      textTransform: 'none',
                      flexDirection: 'column'
                    }}
                  >
                    <SearchIcon sx={{ color: '#fff' }} />
                    <Typography variant='caption' sx={{ color: '#fff' }}>
                      Search
                    </Typography>
                  </Button>
                  <Button
                    component={Link}
                    to='/data'
                    sx={{
                      color: '#ffffff',
                      textTransform: 'none',
                      flexDirection: 'column'
                    }}
                  >
                    <TimelineIcon sx={{ color: '#fff' }} />
                    <Typography variant='caption' sx={{ color: '#fff' }}>
                      Market
                    </Typography>
                  </Button>
                  <Button
                    component={Link}
                    to='/profile'
                    sx={{
                      color: '#ffffff',
                      textTransform: 'none',
                      flexDirection: 'column'
                    }}
                  >
                    <ProfileIcon sx={{ color: '#fff' }} />
                    <Typography variant='caption' sx={{ color: '#fff' }}>
                      Profile
                    </Typography>
                  </Button>
                </Box>
              )}
            </AppBar>

            {/* Drawer for Mobile Navigation */}
            <nav>
              <Drawer
                container={container}
                variant='temporary'
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }}
                sx={{
                  display: { sm: 'block', md: 'none' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth
                  }
                }}
              >
                {drawer}
              </Drawer>
            </nav>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CssBaseline />
            <AppBar component='nav' sx={{ background: '#000000' }}>
              {/* Top Row */}
              <Toolbar>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  edge='start'
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: 'none' } }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant='h6'
                  component='div'
                  sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}
                >
                  <Link to='/'>
                    <img
                      src='/img_frontend/logonews.png'
                      alt='Logo'
                      className='topLogo'
                      style={{
                        width: '50px',
                        height: '50px',
                        objectFit: 'cover',
                        cursor: 'pointer'
                      }}
                    />
                  </Link>
                </Typography>

                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {navItems.map(item => (
                    <Button
                      variant={
                        location.pathname === `${item.to}`
                          ? 'contained'
                          : 'outlined'
                      }
                      sx={{
                        background:
                          location.pathname.toLowerCase() === `${item.to}`
                            ? '#212020 !important'
                            : '#010101 !important',
                        borderRadius: '30px',
                        marginRight: '10px'
                      }}
                      key={item.text}
                      onClick={() => setSelectedCategory(item.to)}
                    >
                      <Link
                        to={item.to}
                        style={{ color: '#ffffff', textDecoration: 'none' }}
                      >
                        {item.text}
                      </Link>
                    </Button>
                  ))}
                </Box>

                <Button
                  variant={
                    location.pathname === '/indian' ? 'contained' : 'outlined'
                  }
                  sx={{
                    background:
                      location.pathname.toLowerCase() === '/indian'
                        ? '#212020 !important'
                        : '#010101 !important',
                    borderRadius: '30px',
                    marginRight: '10px'
                  }}
                  onClick={() => setSelectedCategory('indian-news')}
                >
                  <Link
                    to='/indian'
                    style={{ color: '#ffffff', textDecoration: 'none' }}
                  >
                    Indian News
                  </Link>
                </Button>
                <Button
                  sx={{
                    background:
                      location.pathname.toLowerCase() === '/global'
                        ? '#212020 !important'
                        : '#010101 !important',
                    borderRadius: '30px'
                  }}
                  variant={
                    selectedCategory === 'global-news'
                      ? 'contained'
                      : 'outlined'
                  }
                  onClick={() => setSelectedCategory('global-news')}
                >
                  <Link
                    to='/global'
                    style={{ color: '#ffffff', textDecoration: 'none' }}
                  >
                    Global News
                  </Link>
                </Button>
                <Button
                  sx={{
                    background:
                      location.pathname.toLowerCase() === '/blogs'
                        ? '#212020 !important'
                        : '#010101 !important',
                    borderRadius: '30px'
                  }}
                  variant={
                    selectedCategory === 'blog-news' ? 'contained' : 'outlined'
                  }
                  onClick={() => setSelectedCategory('blog-news')}
                >
                  <Link
                    to='/blogs'
                    style={{ color: '#ffffff', textDecoration: 'none' }}
                  >
                    Blogs
                  </Link>
                </Button>
              </Toolbar>
              {/* Bottom Row */}

              {!isMobile && (
                <Box
                  sx={{
                    background: '#202020',
                    display: { xs: 'none', sm: 'flex' }, // Hide on mobile (xs), show on sm and up
                    justifyContent: 'space-around',
                    paddingY: 1
                  }}
                >
                  <Button
                    component={Link}
                    to='/'
                    sx={{
                      color: '#ffffff',
                      textTransform: 'none',
                      flexDirection: 'column'
                    }}
                  >
                    <HomeIcon sx={{ color: '#fff' }} />
                    <Typography variant='caption' sx={{ color: '#fff' }}>
                      Home
                    </Typography>
                  </Button>
                  <Button
                    component={Link}
                    to='/settings'
                    sx={{
                      color: '#ffffff',
                      textTransform: 'none',
                      flexDirection: 'column'
                    }}
                  >
                    <SettingsIcon sx={{ color: '#fff' }} />
                    <Typography variant='caption' sx={{ color: '#fff' }}>
                      Settings
                    </Typography>
                  </Button>
                  <Button
                    component={Link}
                    to='/search'
                    sx={{
                      color: '#ffffff',
                      textTransform: 'none',
                      flexDirection: 'column'
                    }}
                  >
                    <SearchIcon sx={{ color: '#fff' }} />
                    <Typography variant='caption' sx={{ color: '#fff' }}>
                      Search
                    </Typography>
                  </Button>
                  <Button
                    component={Link}
                    to='/data'
                    sx={{
                      color: '#ffffff',
                      textTransform: 'none',
                      flexDirection: 'column'
                    }}
                  >
                    <TimelineIcon sx={{ color: '#fff' }} />
                    <Typography variant='caption' sx={{ color: '#fff' }}>
                      Market
                    </Typography>
                  </Button>
                  <Button
                    component={Link}
                    to='/profile'
                    sx={{
                      color: '#ffffff',
                      textTransform: 'none',
                      flexDirection: 'column'
                    }}
                  >
                    <ProfileIcon sx={{ color: '#fff' }} />
                    <Typography variant='caption' sx={{ color: '#fff' }}>
                      Profile
                    </Typography>
                  </Button>
                </Box>
              )}
            </AppBar>

            {/* Drawer for Mobile Navigation */}
            <nav>
              <Drawer
                container={container}
                variant='temporary'
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }}
                sx={{
                  display: { sm: 'block', md: 'none' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth
                  }
                }}
              >
                {drawer}
              </Drawer>
            </nav>
          </Box>
        )}
      </ThemeProvider>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={{ ...style, position: 'relative' }}>
          <CloseIcon
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: 16,
              right: 16,
              cursor: 'pointer'
            }}
          />
          <LoginForm />
        </Box>
      </Modal>

      <Modal
        open={showSignupForm}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={{ ...style, position: 'relative' }}>
          <CloseIcon
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: 16,
              right: 16,
              cursor: 'pointer'
            }}
          />
          <SignupForm />
        </Box>
      </Modal>
    </>
  )
}

Header.propTypes = {
  window: PropTypes.func
}

export default Header
