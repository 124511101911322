import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './ImageWithLoader.scss';
import { CircularProgress, Box } from '@mui/material';

const ImageWithLoader = ({ src = false, alt, style }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <Box
      sx={{
        position: 'relative',
        width: style?.width || '100%',
        height: style?.height || 'auto'
      }}
    >
      {!isLoaded && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!src && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <img
        src={src}
        alt={alt}
        style={{
          ...style,
          display: isLoaded ? 'block' : 'none'
        }}
        onLoad={() => setIsLoaded(true)}
      />
    </Box>
  )
}

export default ImageWithLoader
