import React from "react";
import { Container, Typography, Box, Paper, Divider, Grid } from "@mui/material";

const AboutPage = () => {
  return (
    <Container maxWidth="lg">
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          marginTop: 20,
          backgroundColor: "#ffffff",
          borderLeft: "5px solid #e48c48",
        }}>
        <Box textAlign="center" mb={4}>
          <img src="/img_frontend/logonews.png" alt="DairyNews7x7 Logo" style={{ width: "200px", height: "auto" }} />
        </Box>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            color: "#31404b",
            textTransform: "uppercase",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "20px",
          }}>
          About DairyNews7x7
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ color: "#31404b", fontWeight: "bold", mb: 2 }}>
              Our Mission
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: "#9a9da2" }}>
              DairyNews7x7 is India's premier digital platform dedicated to the dairy industry. We deliver comprehensive, timely, and
              accurate news coverage of the entire dairy ecosystem, from farm to table.
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: "#9a9da2" }}>
              Our mission is to empower stakeholders across the dairy sector with crucial information, insights, and analysis that drive
              informed decision-making and industry growth.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ color: "#31404b", fontWeight: "bold", mb: 2 }}>
              What We Cover
            </Typography>
            <Box component="ul" sx={{ color: "#9a9da2" }}>
              <li>Latest dairy industry news and developments</li>
              <li>Market trends and price analysis</li>
              <li>Policy updates and regulatory changes</li>
              <li>Technology and innovation in dairy farming</li>
              <li>Expert interviews and opinion pieces</li>
              <li>International dairy market insights</li>
              <li>Success stories from dairy farmers</li>
            </Box>
          </Grid>
        </Grid>

        <Box mt={4}>
          <Typography variant="h6" sx={{ color: "#31404b", fontWeight: "bold", mb: 2 }}>
            Our Services
          </Typography>
          <Grid container spacing={3}>
            {[
              "Daily news updates",
              "Market price tracking",
              "Industry event coverage",
              "Expert analysis",
              "Video content",
              "Educational resources",
              "Newsletter service",
              "Digital magazine",
            ].map((service, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 2,
                    textAlign: "center",
                    backgroundColor: "#f8f9fa",
                    "&:hover": {
                      backgroundColor: "#e48c48",
                      color: "#31404b",
                    },
                  }}>
                  {service}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box mt={4} textAlign="center">
          <Typography variant="h6" sx={{ color: "#31404b", fontWeight: "bold", mb: 2 }}>
            Connect With Us
          </Typography>
          <Typography variant="body1" sx={{ color: "#9a9da2" }}>
            Join our growing community of dairy industry professionals and stay updated with the latest developments.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default AboutPage;
