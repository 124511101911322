import React, { useEffect } from "react";

const GoogleTranslate = () => {
    useEffect(() => {
        const scriptId = "google-translate-script";

        // If script already exists, initialize TranslateElement
        if (document.getElementById(scriptId)) {
            initializeGoogleTranslate();
            return;
        }

        // Create and load the Google Translate script
        const script = document.createElement("script");
        script.id = scriptId;
        script.src = "https://translate.google.com/translate_a/element.js?cb=initGoogleTranslate";
        script.async = true;
        script.defer = true;

        // Append script to body
        document.body.appendChild(script);

        // Define the global callback function
        window.initGoogleTranslate = initializeGoogleTranslate;
    }, []);

    const initializeGoogleTranslate = () => {
        if (window.google && window.google.translate) {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: "en",
                    layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
                },
                "google_translate_element"
            );
        } else {
            console.error("Google Translate failed to load properly.");
        }
    };

    return (
        <div>
            <div id="google_translate_element"></div>
        </div>
    );
};

export default GoogleTranslate;
