import React from "react";
import { Container, Typography, Box, Grid, Paper, useMediaQuery } from "@mui/material";
import { LocationOn, Phone, Email } from "@mui/icons-material";

const Contact = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <Container maxWidth="lg">
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          marginTop: 20,
          backgroundColor: "#ffffff",
          borderLeft: "5px solid #e48c48",
        }}>
        <Box textAlign="center" mb={4}>
          <img src="/img_frontend/logonews.png" alt="DairyNews7x7 Logo" style={{ width: "200px", height: "auto" }} />
        </Box>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            color: "#31404b",
            mb: 4,
            textTransform: "uppercase",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "20px",
          }}>
          Contact Us
        </Typography>

        <Grid container spacing={4} justifyContent="center" alignItems="stretch">
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={4}
              sx={{
                p: 3,
                height: "100%",
                backgroundColor: "#ffffff",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: 6,
                  borderLeft: "5px solid #e48c48",
                },
              }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <LocationOn sx={{ fontSize: 40, color: "#e48c48", mb: 2 }} />
                <Typography variant="h6" gutterBottom sx={{ color: "#31404b", fontWeight: "bold" }}>
                  Address
                </Typography>
                <Typography align="center" sx={{ color: "#9a9da2" }}>
                  C-49, C Block, Sector 65, Noida,
                </Typography>
                <Typography align="center" sx={{ color: "#9a9da2" }}>
                  Uttar Pradesh 201307, India
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={4}
              sx={{
                p: 3,
                height: "100%",
                backgroundColor: "#ffffff",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: 6,
                  borderLeft: "5px solid #e48c48",
                },
              }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Phone sx={{ fontSize: 40, color: "#e48c48", mb: 2 }} />
                <Typography variant="h6" gutterBottom sx={{ color: "#31404b", fontWeight: "bold" }}>
                  Phone
                </Typography>
                <Typography align="center" sx={{ color: "#9a9da2" }}>
                  0120-4320845
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              elevation={4}
              sx={{
                p: 3,
                height: "100%",
                backgroundColor: "#ffffff",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: 6,
                  borderLeft: "5px solid #e48c48",
                },
              }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Email sx={{ fontSize: 40, color: "#e48c48", mb: 2 }} />
                <Typography variant="h6" gutterBottom sx={{ color: "#31404b", fontWeight: "bold" }}>
                  Email
                </Typography>
                <Typography align="center" sx={{ color: "#9a9da2" }}>
                  dairynews7x7@gmail.com
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Contact;
