import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Modal, IconButton, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FetchChannelVideosApi } from '../../services/apis';
import './WeeklyNews.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const WeeklyNews = () => {
  const [cards, setCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const decodeHtml = html => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.documentElement.textContent;
  };

  const fetchData = async () => {
    try {
      const response = await FetchChannelVideosApi();
      const data = Array.isArray(response.videos) ? response.videos : [];
      setCards(data);
      setFilteredCards(data);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setCards([]);
      setFilteredCards([]);
    }
  };

  const handleSearch = event => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = cards.filter(
      card =>
        card.title.toLowerCase().includes(query) ||
        card.content.toLowerCase().includes(query)
    );
    setFilteredCards(filtered);
  };

  const handleOpenModal = video => {
    setSelectedVideo(video);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedVideo(null);
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Group videos into sets of 4
  const groupedVideos = chunkArray(filteredCards, 2);

  return (
    <>
      {/* Video Slider - Bootstrap Carousel */}
      <div id="videoCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          {groupedVideos.length > 0 ? (
            groupedVideos.map((group, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? 'active' : ''}`}
              >
                <div className="row">
                  {group.map((card, idx) => (
                    <div
                      key={card.id || idx}
                      className="col-md-6"
                      onClick={() => handleOpenModal(card)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Box
                        style={{
                          backgroundImage: `url(${
                            card.thumbnail || 'https://via.placeholder.com/300'
                          })`,
                          height: '200px',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          borderRadius: '10px',
                          margin: '10px',
                        }}
                      ></Box>
                      <Typography
                        className="text-center mt-2"
                        style={{ fontSize: '14px' }}
                      >
                        {decodeHtml(card.title)}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <Typography>No videos found.</Typography>
          )}
        </div>
        {/* Carousel controls */}
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#videoCarousel"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#videoCarousel"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* Modal for Video */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="video-modal-title"
        aria-describedby="video-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '800px',
            bgcolor: '#ffffff',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          {selectedVideo && (
            <Box>
              <Typography
                id="video-modal-title"
                variant="h6"
                component="h2"
                sx={{ marginBottom: '20px' }}
              >
                {decodeHtml(selectedVideo.title)}
              </Typography>
              <Box sx={{ aspectRatio: '16/9', marginBottom: '20px' }}>
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${selectedVideo.video_id}`}
                  title={selectedVideo.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Box>
              <Typography id="video-modal-description">
                {decodeHtml(selectedVideo.description || 'No description available.')}{' '}
                <Link
                  href={`https://www.youtube.com/watch?v=${selectedVideo.video_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: 'blue', textDecoration: 'underline' }}
                >
                  Read More
                </Link>
              </Typography>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default WeeklyNews;
