import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import './Data.scss' // Ensure your CSS is updated
import { WorkSheetApi } from '../../services/apis' // Replace with your API call utility

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const Data = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await WorkSheetApi()
        setData(result)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  if (loading) return <p className='loading'>Loading...</p>
  if (error) return <p className='error'>Error: {error}</p>

  return (
    <div className='data-container'>
      {data.map(worksheet => {
        const commodityNames = worksheet.commodities.map(
          commodity => commodity.name
        )

        // Extract unique component headings dynamically
        const componentHeadings = worksheet.commodities
          .flatMap(commodity => commodity.details)
          .reduce((acc, detail) => {
            if (!acc.includes(detail.component_name))
              acc.push(detail.component_name)
            return acc
          }, [])

        // Prepare datasets for the chart
        const datasets = componentHeadings.map((heading, index) => {
          const componentValues = worksheet.commodities.map(commodity => {
            const detail = commodity.details.find(
              d => d.component_name === heading
            )
            return parseFloat(detail?.component_value) || 0
          })

          return {
            label: heading, // Use component heading as the label
            data: componentValues,
            borderColor: `hsl(${index * 60}, 70%, 50%)`,
            backgroundColor: `hsla(${index * 60}, 70%, 50%, 0.2)`,
            tension: 0.3,
            fill: false
          }
        })

        const chartData = {
          labels: commodityNames,
          datasets: datasets
        }

        return (
          <div key={worksheet.id} className='worksheet'>
            <h2 className='worksheet-title'>{worksheet.heading}</h2>
            <p className='worksheet-description'>{worksheet.description}</p>

            {/* Line Chart */}
            <div className='chart-container'>
              <Line
                data={chartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false, // Allow the chart to adjust to its container
                  plugins: {
                    legend: {
                      position: 'top' // Adjust legend position for better visibility
                    },
                    title: {
                      display: true,
                      text: `${worksheet.name} Comparison`
                    }
                  },
                  scales: {
                    x: {
                      title: {
                        display: true,
                        text: 'Commodities'
                      },
                      ticks: {
                        autoSkip: true, // Automatically skip labels to fit
                        maxRotation: 45, // Reduce label rotation
                        minRotation: 0
                      }
                    },
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: 'Values'
                      },
                      ticks: {
                        stepSize: 10 // Optional: Adjust step size for better readability
                      }
                    }
                  }
                }}
                style={{ width: '100%', height: '300px' }} // Set height to allow scaling
              />
            </div>

            {/* Table */}
            <div className="overflow-x-scroll">
              <table className='data-table'>
                <thead>
                  <tr>
                    <th>Commodity</th>
                    {componentHeadings.map((heading, index) => (
                      <th key={index}>{heading}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {worksheet.commodities.map(commodity => (
                    <tr key={commodity.id}>
                      <td>{commodity.name}</td>
                      {componentHeadings.map((heading, index) => {
                        const detail = commodity.details.find(
                          d => d.component_name === heading
                        )
                        return (
                          <td key={index}>
                            {detail?.component_value || 'N/A'}
                          </td>
                        )
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Data
